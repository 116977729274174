.contact_user {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	position: relative;
	gap: 1rem;
	padding: 0 10px;
	background: var(--cards-effects-background_surface);
	border: var(--cards-effect-border);
}

.contact_user:hover, .contact_user.active {
	background-color: rgba(66, 62, 201, 0.12);
	.bar_hover {
		background-color: #423ec9;
		height: 50%;
	}
}

.profile_pic {
	position: relative;
	width: 50px;
	height: 50px;
}

.profile_image {
	width: 50px;
	height: 50px;
	object-fit: cover;
	border-radius: 50%;
}

.status {
	position: absolute;
	bottom: 0;
	right: 0;
	width: 17px;
	height: 17px;
	border-radius: 50%;
	background-color: #4caf50;
	border: 2px solid #181719;
}

.name_and_message h2 {
	color: white;
	font-weight: normal;
}

.name_and_message div {
	width: 100%;
	display: flex;
	gap: 0.5rem;
	align-items: center;
}

.name_and_message div h2 {
	max-width: 170px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.name_and_message div p {
	color: #9d98b4;
	font-size: 0.8rem;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.small_ellipse {
	width: 4px !important;
	height: 4px;
	border-radius: 50%;
	background-color: #b5b5b5;
	text-align: center;
	align-self: center;
}

.message {
	max-width: 300px;
	color: #9d98b4;
	font-size: 0.8rem;
	letter-spacing: 1px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.message_indicator {
	min-width: 25px;
	min-height: 25px;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	background-color: #423ec9;
	margin: 0 0 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;
}

.message_indicator p {
	text-align: center;
	color: var(--text-primary);
}

.bar_hover {
	width: 6px;
	height: 0%;
	background-color: transparent;
	position: absolute;
	left: -16px;
	border-radius: 0 10px 10px 0;
	transition: height 0.3s ease-in-out;
}

@media only screen and (max-width: 1100px) {
	.contact_user {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		position: relative;
		gap: 1rem;
		background: var(--cards-effects-background_surface);
		border: var(--cards-effect-border);
	}
	.contact_user:hover {
		background-color: rgba(66, 62, 201, 0.12);

		.bar_hover {
			background-color: #423ec9;
			height: 50%;
		}
	}
	.name_and_message div {
		width: 100%;
		display: flex;

		gap: 0.5rem;
	}
	.name_and_message div p {
		color: #9d98b4;
	}
	.small_ellipse {
		width: 5px !important;
		height: 5px;
		border-radius: 50%;
		background-color: #b5b5b5;
		text-align: center;
		align-self: center;
	}
	.message {
		max-width: 300px;
		color: #9d98b4;
		font-size: 0.8rem;
		letter-spacing: 1px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.message_indicator {
		min-width: 25px;
		min-height: 25px;
		width: 25px;
		height: 25px;
		border-radius: 50%;
		background-color: #423ec9;
		margin: 0 0 0 auto;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.message_indicator p {
		text-align: center;
		color: var(--text-primary);
	}

	.bar_hover {
		display: none;
	}
}
