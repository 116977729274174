.user_profile_root_container {
	border: 1px solid rgba(56, 56, 56, 1);
	border-radius: 30px;
	padding: 10px;
	width: 100%;
	background: linear-gradient(
		89.78deg,
		rgba(31, 30, 30, 0.5) 3.46%,
		rgba(33, 32, 33, 0.5) 99.5%
	);
	overflow: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.tablayout {
	width: 100%;
	justify-content: center;
	margin-top: 70px;
	display: flex;
	max-width: 600px;
	align-items: center;
}

.tabs_content_container {
	margin-top: 25px;
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	position: relative;
}

.title_container {
	display: none;
}

@media (max-width: 998px) {
	.user_profile_root_container {
		overflow: initial;
	}

	.inactive_user_profile {
		display: none;
	}

	.user_profile_root_container {
		border: none;
		border-radius: 0px;
		background: none;
	}

	.page_title {
		font-size: 1.5rem;
		color: var(--text-primary);
		margin-bottom: 0;
	}

	.back_icon {
		background: none;
		border: none;
		outline: none;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 15px;
		cursor: pointer;
	}

	.title_container {
		display: flex;
		align-items: center;
		margin-bottom: 1rem;
		margin: 0.5rem 0 1.1rem 0;
		align-self: self-start;
	}
}

@media screen and (max-width: 600px){
	.user_profile_root_container {
		border: none;
		border-radius: 0px;
		background: none;
	}
}