.root_view {
	grid-area: main;
	height: calc(100vh - var(--navbar-height) - 4rem);
	display: flex;
	justify-content: center;
	padding: 1rem 1rem 0 1rem;
	width: 100%;
}

.main_holder {
	max-width: 1836px;
	border-radius: 30px;
	display: flex;
	gap: 1rem;
	overflow: hidden;
	width: 100%;
	padding: 10px 10px 10px 0px;
	animation: enterance 0.4s ease-in-out;
	backdrop-filter: var(--cards-effect-blur);
	background: var(--cards-effect-background);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
}

@keyframes enterance {
	0% {
		opacity: 0;
		transform: translateY(20px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@media only screen and (max-width: 1100px) {
	.main_holder {
		padding: 0 0 10px 0px;
	}

	.add_background {
		background: none;
		box-shadow: none;
		border: none;
	}

	.root_view {
		padding-top: 0;
	}
}

@media only screen and (max-width: 600px) {
	.root_view {
		margin: 0;
		padding: 0;
		height: calc(100svh - var(--navbar-height-mobile) * 2);
	}

	.main_holder {
		overflow: hidden;
		border: none;
		box-shadow: none;
		border-radius: 0px;
		padding: 0;
	}
}
