.contact_profile_root,
.active_profile {
	width: 100%;
	max-width: 440px;
	height: 100%;
	display: flex;
	justify-content: flex-end;
	position: relative;
	transition: 1s;
	overflow: hidden;
}

.exit_profile {
	display: none;
}

.contact_profile_card {
	width: 100%;
	max-width: 440px;
	height: 100%;
	border-radius: 30px;
	background: var(--cards-effect-background_secondary);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
	padding: 10px;
	overflow-y: auto;
}

.contact_profile_card::-webkit-scrollbar {
	display: none;
}

.contact_pfp_cover {
	position: relative;
}

.contact_pfp {
	width: 140px;
	height: 140px;
	border-radius: 50%;
	position: absolute;
	bottom: -30px;
	object-fit: cover;
	left: 50%;
	transform: translateX(-50%);
	border: 5px solid var(--cards-effect-border);
}

.contact_cover {
	width: 100%;
	height: 150px;
	border-radius: 25px;
	object-fit: cover;
	display: flex;
}

.contact_profile_name_status {
	padding: 40px 0px 0px 0px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.contact_profile_name_status h1 {
	font-size: 2rem;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 22.5rem;
	-webkit-line-clamp: 2;
	line-clamp: 2;
	font-weight: normal;
	color: var(--text-primary);
	text-align: center;
}

.contact_profile_name_status p {
	font-size: 1rem;
	margin-top: 7px;
	color: #b3b3b3;
}

/* start contact profile actions */

.actions__holder {
	position: relative;
	top: 0;
	left: 0;
	height: 106px;
	width: 100%;
	border-radius: 30px;
	background: var(--cards-effect-background_secondary);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
	margin: 20px 0px;
	overflow: hidden;
}

.profile_actions__holder {
	position: absolute;
	left: 0;
	height: 100%;
	display: flex;
	width: 100%;
	max-width: 440px;
}

.profile_actions__holder.show_actions {
	left: 0;
	transition: ease-in-out 0.3s;
}

.profile_actions__holder.hide_actions {
	left: -120%;
	transition: ease-in-out 0.3s;
}

.contact_profile_actions {
	position: absolute;
	left: 0;
	color: var(--text-primary);
	display: flex;
	justify-content: space-between;
	padding: 5px 5px;
	width: 100%;
	height: 100%;
}

.contact_profile_actions div:nth-child(odd) {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 10px;
	align-items: center;
	justify-content: center;
	transition: 0.3s;
	padding: 15px 0px;
}

.contact_profile_actions div:nth-child(odd):hover {
	background: #0000001d;
	border-radius: 25px;
	cursor: pointer;
	transition: 0.3s;
}

.contact_profile_games {
	position: absolute;
	left: 120%;
	color: var(--text-primary);
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 5px 5px;
	width: 100%;
	height: 100%;
}

.contact_profile_games__back {
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 10px;
	align-items: center;
	justify-content: center;
	transition: 0.3s;
	padding: 16.5px 0px;
	width: calc(100% / 3);
	background: var(--cards-effect-background);
	border: var(--cards-effect-border);
	border-radius: 25px;
	margin-right: 5px;
	cursor: pointer;
}

.contact_profile_games__available {
	display: flex;
	height: 100%;
	width: calc(100% - (100% / 3));
	background: var(--cards-effect-background);
	border-radius: 25px;
	margin-left: 5px;
	border: var(--cards-effect-border);
}

.contact_profile_games__ping_pong,
.contact_profile_games_tic_tac_toe {
	height: 100%;
	width: calc(100% / 2);
	display: flex;
	flex-direction: column;
	gap: 10px;
	align-items: center;
	justify-content: center;
	transition: 0.3s;
	padding: 15px 0px;
	cursor: pointer;
}

/* end contact profile actions */

/* start tab layout of history and friends */
.contact_profile_tablayout {
	border-radius: 30px;
	background: var(--cards-effect-background_secondary);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
	display: flex;
	justify-content: space-evenly;
	padding: 0px 5px;
	margin: 20px 0px 10px 0px;
	align-items: center;
	height: 50px;
	overflow: auto;
}

.contact_profile_tablayout p {
	color: var(--text-primary);
}

.contact_profile_tablayout_history_tab,
.contact_profile_tablayout_friends_tab {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0px 5px;
	transition: 0.3s;
	height: 80%;
	width: 50%;
}

.contact_profile_tablayout_history_tab:hover,
.contact_profile_tablayout_friends_tab:hover {
	background: #0000001d;
	border-radius: 25px;
	cursor: pointer;
	transition: 0.3s;
}

.tab_indicator {
	position: absolute;
	bottom: 0;
	right: 66px;
	width: 60px;
	height: 4px !important;
	background-color: var(--text-primary);
	border-radius: 5px 5px 0px 0px;
	transition: 0.3s;
}

.contact_profile_tablayout div:nth-child(2) {
	height: 50%;
}

/*  end tab layout of history and friends */

/* start history and friends list */

.contact_matches_history_card {
	border-radius: 20px;
	color: var(--text-primary);
	display: flex;
	justify-content: space-between;
	transition: 0.3s;
	display: flex;
	flex-direction: column;
	max-height: calc(100% - 450px);
}

.contact_matches_history_card::-webkit-scrollbar {
	display: none;
}

.historyContainer {
	width: 100%;
	height: fit-content;
	border-radius: 20px;
	padding: 0.5rem 0 0rem 0;
}

/* end history and friends list */

.separator {
	width: 1px;
	height: 50px;
	margin: 0px 10px;
	background: var(--separator-color);
	align-self: center;
}

@media (max-width: 1700px) {

	.active_profile {
		max-width: 100%;
		transform: translateX(0);
		transition: 0;
		width: 100%;
		margin-left: 0px;
	}
}

@media only screen and (max-width: 1550px) {
	.contact_profile_root {
		display: none;
	}
}

@media only screen and (max-width: 1100px) {
	.contact_profile_root {
		display: none;
	}

	.contact_profile_card {
		border: none;
		max-width: 100%;
		border-radius: 0;
		position: relative;
	}

	.profile_actions__holder {
		max-width: 100%;
	}

	.exit_profile {
		position: absolute;
		top: 20px;
		right: 20px;
		display: flex;
		z-index: 100;
		cursor: pointer;
	}
}

.contacts_ul {
	width: 100%;
	height: 100%;
	max-height: 500px;
	margin: 0;
}

.contact_matches_history_root {
	width: 100%;
}

.contact_user {
	height: 90px;
	display: flex;
	align-items: center;
	padding: 8px 15px;
	transition: 0.3s;
	position: relative;
}

.status_tab_right {
	position: absolute;
	right: 0;
	top: 50;
	width: 5px;
	height: 50%;
	border-radius: 25px 0px 0px 25px;
	transition: 0.3s;
}

.status_tab_left {
	position: absolute;
	left: 0;
	top: 50;
	width: 5px;
	height: 50%;
	border-radius: 0px 25px 25px 0px;
	transition: 0.3s;
}

.history_list_item {
	width: 100%;
	height: 100%;
	border-radius: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: var(--cards-effect-background_secondary);
	-webkit-backdrop-filter: var(--cards-effect-blur);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
	animation: slide-in 0.3s;
}

@keyframes slide-in {
	from {
		transform: translateX(100%);
	}
	to {
		transform: translateX(0);
	}
}

.user_profile_pic {
	width: 95px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
}

.game {
	width: 95px;
	height: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: flex-end;
}

.game_title {
	width: 100%;
}

.user_profile_pic img {
	width: 45px;
	height: 45px;
	border-radius: 50%;
	object-fit: cover;
}
