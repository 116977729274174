.loading_view {
	width: 100%;
	border-radius: 20px;
	background: var(--cards-effect-background_secondary);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
	display: flex;
	align-items: center;
	padding: 0.7rem 0.6rem;
	margin-top: 1rem;
}

.loading_inner_view {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 20px;
	padding-left: 1rem;
}

.two_fauth_root_container {
	width: 100%;
	margin-top: 3rem;
}

.two_fauth_title {
	margin-left: 6px;
	font-size: 16px;
	color: rgba(163, 163, 163, 1);
	font-weight: normal;
}

.two_fauth_card {
	margin-top: 12px;
	width: 100%;
	border: var(--cards-effect-border);
	border-radius: 20px;
	display: flex;
	padding: 0.7rem 0.6rem;
	gap: 1.5rem;
	align-items: center;
}

.two_fauth_qrcode {
	width: 150px;
	height: 150px;
	border-radius: 14px;
}

.two_fauth_form {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.info_item {
	display: flex;
	height: 60px;
	width: 100%;
	align-items: center;
	background: var(--cards-effect-background_secondary);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
	border-radius: 18px;
	padding: 0 14px;
	gap: 10px;
}

.info_item img {
	height: 25px;
	width: 25px;
}

.info_item input {
	width: 100%;
	background: none;
	border: none;
	outline: none;
	color: var(--text-primary);
	font-size: 16px;
}

.input_layout_container {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.input_layout_container label {
	padding: 0px 4px 10px 4px;
	font-size: 16px;
	color: rgba(163, 163, 163, 1);
}

.save_changes_button {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px 20px;
	border-radius: 12px;
	gap: 10px;
	cursor: pointer;
	margin-top: 5px;
	border: none;
	outline: none;
	transition: all ease-in-out 0.2s;
}

.save_changes_button:hover {
	transform: scale(1.01);
}

.save_changes_button h1 {
	font-size: 16px;
	font-weight: normal;
}


.save_changes_button img {
	height: 20px;
	width: 20px;
}

.two_fauth_enabled_card {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin-top: 12px;
	background: var(--cards-effect-background_secondary);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
	border-radius: 20px;
	padding: 1.5rem 0.6rem;
	gap: 1.5rem;
	margin-top: 3rem;
}

.two_fauth_enabled_card h1 {
	font-size: 18px;
	color: rgba(163, 163, 163, 1);
	font-weight: normal;
}

.two_fauth_enabled_card p {
	font-size: 15px;
	color: white;
}

.two_fauth_enabled_card button {
	background: rgba(191, 97, 97, 1);
}