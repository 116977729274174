.notifications {
	position: absolute;
	top: 50%;
	left: 0;
	height: 0;
	width: 100%;
	z-index: 2;
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
	transition: all 0.5s ease;
	overflow: hidden;
	background: var(--cards-effect-background_secondary);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
}

.notifications.active {
	height: 324px;
	transition: all 0.5s ease;
	transition-delay: 0.3s;
}

.notifications__holder {
	margin-top: 30px;
	height: 294px;
	width: 100%;
	overflow: auto;
	display: flex;
	flex-direction: column;
}

.notifications__holder::-webkit-scrollbar {
	display: none;
}

.notification {
	border: var(--cards-effect-border);
	border-radius: 12px;
	background: var(--cards-effect-background_secondary);
	margin: 5px;
	padding: 8px 6px;
	display: flex;
	gap: 10px;
}

.notification__img {
	width: 35px;
	height: 35px;
	overflow: hidden;
	border-radius: 50%;
}

.notification__text__holder {
	height: 100%;
	align-self: center;
	color: var(--text-primary);
	width: calc(100% - 56px);
	display: flex;
	flex-direction: column;
	gap: 3px;
}

.notification__text__holder h5 {
	font-size: 0.8rem;
	width: 100%;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.notification__text__holder p {
	font-size: 0.75rem;
	width: 100%;
	color: #dfdfdf;
}

.buttonsContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 0.4rem;
	padding: 7px 0 2px 0;
}

.notification__button {
	width: 100%;
	color: var(--text-primary);
	border: none;
	border-radius: 20px;
	padding: 0.35rem 0.5rem;
	font-size: 0.75rem;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
}

.notification__button:nth-child(1) {
	background: #3a9163;
}

.notification__button:nth-child(2) {
	background: #f95a5a;
}

.notification__button:nth-child(1):hover {
	background: #2b724c;
}

.notification__button:nth-child(2):hover {
	background: #bf4343;
}

.no_notifications {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 100%;
	color: var(--text-primary);
	gap: 0.7rem;
}

@media only screen and (max-width: 600px) {
	.notifications {
		top: 90px;
		left: auto;
		right: -120%;
		width: 76%;
		margin: 0 auto;
		background-color: black;
		border-radius: 30px;
		height: 292px;
	}

	.notifications.active {
		right: 1rem;
		height: 292px;
	}

	.notifications__holder {
		margin-top: 0;
		height: 292px;
	}

	.notification {
		border-radius: 25px;
	}
}
