.loader {
  width: 50px;
	height: 50px;
	display: flex;
	justify-content: space-evenly;
  border-radius: 70%;
  align-items: center;
	object-fit: scale-down;
  background: var(--cards-effect-background_secondary);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
}

.ball {
	list-style: none;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: #fff;
}

.ball:nth-child(1) {
	animation: bounce-1 2.1s ease-in-out infinite;
}

@keyframes bounce-1 {
	50% {
		transform: translateY(-8px);
		background-color: rgb(31, 9, 70);
	}
}

.ball:nth-child(2) {
	animation: bounce-3 2.1s ease-in-out 0.3s infinite;
}

@keyframes bounce-2 {
	50% {
		transform: translateY(-8px);
		background-color: rgb(31, 9, 70);
	}
}

.ball:nth-child(3) {
	animation: bounce-3 2.1s ease-in-out 0.6s infinite;
}

@keyframes bounce-3 {
	50% {
		transform: translateY(-8px);
		background-color: rgb(31, 9, 70);
	}
}
