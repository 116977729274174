.root_view {
	width: 100%;
	height: 100%;
	border-radius: 25px;
	display: flex;
	overflow: scroll;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
	backdrop-filter: var(--cards-effect-blur);
	background: var(--cards-effect-background);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
	position: relative;
}

.winner_animation {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.coverImage {
	width: 100%;
	height: 45%;
	object-fit: cover;
	mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
	position: absolute;
	top: 0;
}

.buttonsContainer {
	padding: 0.5rem;
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.button {
	min-width: 250px;
	height: 60px;
	border-radius: 40px;
	padding: 0.4rem;
	display: flex;
	cursor: pointer;
	transition: 0.2s all;
	gap: 0.7rem;
	backdrop-filter: var(--cards-effect-blur);
	background: var(--cards-effect-background_secondary);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
}

.button:hover {
	transform: scale(1.02);
	box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.2) !important;
	transition: 0.2s all;
}

.button p {
	color: #aeaeae;
}

.button h1 {
	color: #fff;
}

.button img {
	height: 45px;
	width: 45px;
	object-fit: cover;
	border-radius: 50%;
}

.buttonTextContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 0.3rem;
	width: 100%;
	height: 100%;
}

.winnerInfoContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 2rem;
	z-index: 1;
	padding-top: 20px;
	transition: 0.2s all;
}

.winnerProfilePictureContainer {
	display: flex;
	position: relative;
	justify-content: center;
	height: 180px;
	margin-top: 40px;
}

.winnerProfilePicture {
	height: 100%;
	border-radius: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.throneImage {
	position: absolute;
	height: 120px;
	top: 52%;
	left: 50%;
}

.winnerTextContainer {
	color: #fff;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 0.1rem;
	padding: 1rem 0;
}

.winnerTextContainer h1 {
	font-family: 'Snes_Regular';
	text-transform: uppercase;
	font-weight: normal;
	font-size: 3.5rem;
	letter-spacing: 0.1rem;
}

.winnerTextContainer h2 {
	font-size: 1rem;
	font-weight: lighter;
	color: #d8d8d8;
}

.playersRecapContainer {
	height: 60px;
	width: 80%;
	max-width: 450px;
	gap: 1rem;
	border-radius: 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.4rem;
	color: white;
	backdrop-filter: var(--cards-effect-blur);
	background: var(--cards-effect-background);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
}

.playersRecapContainer img {
	height: 45px;
	width: 45px;
	object-fit: cover;
	border-radius: 50%;
}

.matchScore {
	display: flex;
	gap: 1rem;
	margin-top: 40px;
	height: 100%;
	width: 82%;
	align-items: center;
	justify-content: space-around;
	transition: 0.2s all;
	padding: 3rem 0;
}

.line {
	height: 100%;
	width: 1px;
	background-color: #5c5c5c;
	transform: rotate(18deg);
}

.final_score_view {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.final_score_view h1 {
	font-family: 'Snes_Regular';
	color: #fff;
	font-size: 170px;
	font-weight: normal;
	text-align: center;
	transform: translateX(-2px);
}

.final_score_view p {
	color: #fff;
	font-size: 0.9rem;
}

@media screen and (max-width: 1500px) {
	.winnerInfoContainer {
		transform: scale(0.8);
		transition: 0.2s all;
		padding-top: 0px;
	}

	.winnerProfilePictureContainer {
		margin-top: 10px;
	}

	.matchScore {
		width: 100%;
		margin-top: 20px;
		/* transform: scale(0.8); */
		transition: 0.2s all;
	}

	.final_score_view h1 {
		font-size: 140px;
	}
}

@media screen and (max-width: 1200px) {
	.root_view {
		gap: 0;
	}

	.matchScore {
		width: 100%;
		margin-top: 0;
		padding: 8rem 0;
	}
}

@media screen and (max-width: 770px) {
	.final_score_view h1 {
		font-size: 120px;
	}

	.matchScore {
		padding: 5rem 0;
	}

	.button {
		min-width: 200px;
	}
}

@media screen and (max-width: 600px) {
	.buttonsContainer {
		flex-direction: column;
		gap: 0.7rem;
	}
}
