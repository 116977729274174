.general_settings_root_container {
	border: 1px solid rgba(56, 56, 56, 1);
	border-radius: 30px;
	padding: 10px;
	width: 100%;
	background: linear-gradient(
		89.78deg,
		rgba(31, 30, 30, 0.5) 3.46%,
		rgba(33, 32, 33, 0.5) 99.5%
	);
	overflow: auto;
	display: flex;
	flex-direction: column;
	padding: 1rem 1.5rem;
}

.page_title {
	font-size: 1.5rem;
	color: var(--text-primary);
	margin-bottom: 1rem;
}

.content_view {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding: 1.5rem 0;
}

.item_title {
	font-size: 1rem;
	font-weight: normal;
	color: var(--text-primary);
}

.language_container {
	display: flex;
	gap: 1rem;
	flex-wrap: wrap;
}

.language_container div {
	font-size: 0.95rem;
	font-weight: normal;
	color: white;
	background: var(--cards-effect-background_secondary);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
	border-radius: 15px;
	padding: 0.7rem 2rem;
	cursor: pointer;
	transition: 0.2s all ease-in-out;
	display: flex;
	align-items: center;
	justify-content: center;
	user-select: none;
}

.language_container div:hover {
	transition: 0.2s all ease-in-out;
	border: solid 1px rgba(255, 255, 255, 0.427);
	transform: scale(1.02);
}

.selected_language {
	border: var(--cards-effect-border);
}

.max_tablayout_width {
	max-width: 485px;
}

.back_icon {
	display: none;
}

@media screen and (max-width: 998px) {
	.general_settings_root_container {
		border: none;
		border-radius: 0;
		height: 100%;
	}

	.back_icon {
		background: none;
		border: none;
		outline: none;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 15px;
		cursor: pointer;
	}

	.title_container {
		display: flex;
		align-items: center;
		margin-bottom: 0.5rem;
	}

	.page_title {
		margin-bottom: 0;
	}
}

@media screen and (max-width: 780px) {
	.max_tablayout_width {
		max-width: 100%;
	}

	.language_container {
		justify-content: space-between;
	}
}

@media screen and (max-width: 600px) {
	.language_container {
		flex-direction: column;
	}
}

@media screen and (max-width: 674px) {
	.language_container div {
		width: 100%;
	}
}
