.search {
	position: relative;
	width: 100%;
	max-width: 700px;
	min-width: 200px;
}

.search_bar {
	position: relative;
	padding-left: 15px;
	width: 100%;
	height: 60px;
	border-radius: 90px;
	display: flex;
	align-items: center;
	gap: 1rem;
	overflow: hidden;
	background: var(--cards-effect-background);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
	transition: all 0.3s ease-in-out;
	z-index: 3;
}

.search_bar:hover {
	box-shadow: 0 0 2px 0 var(--text-secondary);
}

.search_bar .search_icon {
	min-width: 20px;
	width: 20px;
	fill: var(--text-secondary) !important;
}

.search_bar input {
	outline: none;
	border: none;
	background: none;
	color: var(--text-primary);
	overflow: hidden;
	width: 100%;
	height: 100%;
}

.search_bar input::placeholder {
	color: var(--text-secondary);
}
.search_bar input:focus::placeholder {
	color: transparent;
}

.close_icon {
	border: 1px solid #515151;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	padding: 4px;
	margin-right: 10px;
}

.close_icon img {
	width: 14px;
}

.search_bar .exit {
	position: absolute;
	right: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.search_results {
	position: absolute;
	top: 50%;
	left: 0;
	min-height: 100px;
	width: 100%;
	border-radius: 0 0 36px 36px;
	background: var(--cards-effect-background);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
	z-index: 2;
	padding: 30px 6px 6px 6px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	display: none;
}

.focus {
	display: flex;
}

.empty_results {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	color: var(--text-secondary);
}

.result_container {
	display: flex;
	border-radius: 30px;
	background: var(--cards-effect-background_secondary);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
	margin-top: 10px;
	width: 100%;
	cursor: pointer;
}

.result_profile_img {
	width: 60px;
	height: 60px;
	border-radius: 80%;
	margin: 5px;
	object-fit: cover;
}

.result_info_container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-left: 5px;
	gap: 5px;
}

.result_info_container h1 {
	font-size: 1.05rem;
	font-weight: 600;
	color: var(--text-primary);
	margin: 0;
}

.result_info_container p {
	font-size: 0.85rem;
	color: #d2d2d2;
	margin: 0;
}

@media only screen and (max-width: 600px) {
	.search_bar {
		width: 100%;
		transition: all 0.3s ease-in-out;
		height: 50px;
	}

	input::placeholder {
		content: 'test';
	}
}
