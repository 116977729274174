.container {
    max-width: 250px;
    max-height: 250px;
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.circleF {
    display: flex;
    align-items: center;
    justify-content: center;
    stroke-dasharray: 724px;
}
.circle {
    box-shadow: 0 0 11.5px 23px #000000 !important;
}
.shadow {
    position: absolute;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    box-shadow:
        inset 0 0 11.5px 24px rgba(0, 0, 0, 0.25),
        0 0 16.5px 4px rgba(0, 0, 0, 0.25);
    z-index: -1;
}

.firstCircle {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.secondCircle {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}

.secondShadow {
    position: absolute;
    top: 13px;
    left: 13px;
    width: 90%;
    height: 90%;
    border-radius: 50%;
}

.innerCircle {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


.innerCirclePercentage {
    color: var(--text-primary) !important;
    font-size: 1.5rem !important;
    font-weight: bold !important;
}

.innerCircle p {
    color: var(--text-primary);
    font-size: 1rem;
    max-width: 70px;
    text-align: center;
}

.circleS{
    stroke-dashoffset: var(--percentage);
    animation: fill 1s ease-out;
}

@keyframes fill {
    0% {
        stroke-dashoffset: 863px;
    }
    100% {
        stroke-dashoffset: var(--percentage);
    }
}