.SideBar {
	height: 100%;
	width: 100%;
	max-width: 420px;
	display: flex;
	flex-direction: column;
	color: var(--text-primary);
	overflow: auto;
    padding: 10px 0px 0px 10px;
}

.SideBar::-webkit-scrollbar {
	display: none;
}

.SideBarTitle {
	font-size: 2rem;
	color: var(--text-primary);
}

.settingsContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100%;
	padding: 30px 0px 0px 0px;
}

.settingItem {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	background: var(--cards-effect-background_secondary);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
	width: 100%;
	border-radius: 70px;
	margin-bottom: 20px;
	padding: 0.5rem 0;
    transition: 0.2s all ease-in-out;
}

.settingItemImg {
	width: 50px !important;
	height: 50px !important;
	border-radius: 70px;
	margin: 0px 14px 0px 8px;
}

.settingItem:hover {
	cursor: pointer;
    transition: 0.2s all ease-in-out;
	box-shadow: var(--cards-effect-shadow);
}

.settingItemContent {
	display: flex;
	flex-direction: column;
	gap: 0.3rem;
}

.settingItemContent h1 {
	font-size: 1.2rem;
    font-weight: 500;
}

.settingItemContent p {
	font-size: 1rem;
	color: #a3a3a3;
}

.hz {
	width: 95%;
	height: 0.6px;
	margin-bottom: 20px;
	background: #484848;
}


@media only screen and (max-width: 1100px) {
	.SideBar {
		max-width: 360px;
	}
}

@media (max-width: 998px) {
	.SideBar {
		width: 100%;
		max-width: 100%;
		overflow: initial;
	}

	.SideBar.hidden {
		display: none;
	}

	.SideBarTitle h1 {
		font-size: 30px;
	}

	.hide_side_bar {
		display: none;
	}
}

@media screen and (max-width: 600px) {
	.SideBar {
		padding: 10px;
	}
	
}