.tournament_card {
	position: relative;
	width: 100%;
	height: 200px;
	background-size: cover;
	border-radius: 13px;
	overflow: hidden;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
}

.tournament_card::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(357.55deg, rgba(0, 0, 0, 0.96) 2.05%, rgba(0, 0, 0, 0) 97.65%);
}

.tournament_card:hover {
	transform: scale(1.01);
	transition: all 0.3s ease-in-out;
}

.tournament_card__holder {
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	padding: 10px 5px;
	z-index: 2;
}

.tournament_card__text {
	display: flex;
	flex-direction: column;
	max-width: 70%;
}

.tournament_card__title {
	font-size: 2rem;
	color: var(--text-primary);
	margin-left: 8px;
	font-family: 'Snes_Regular';
	font-weight: 400;
	white-space: nowrap;
	overflow: auto;
	text-overflow: ellipsis;
	width: 100%;
}

.tournament_card__subtitle {
	font-size: 1.2rem;
	color: var(--text-primary);
	margin-left: 8px;
	font-family: 'Snes_Regular';
	font-weight: 400;
}

.tournament_card__subtitle img {
	width: 14px;
	margin-left: 6px;
}

.active_players_top {
	display: flex;
	margin-right: 10px;
}

.fraction {
	display: inline-block;
	position: relative;
	vertical-align: middle;
	letter-spacing: 0.001em;
	text-align: center;
	font-weight: normal;
	margin: auto 0;
}

.fraction > span {
	display: block;
	margin-right: 5px;
	color: var(--text-primary);
	font-family: 'Snes_Regular';
	font-size: 1.2rem;
	font-weight: normal;
}

.fraction span.bar {
	width: 78%;
	height: 1px;
	margin-bottom: 5px;
	background-color: var(--text-primary);
}

.active_player_text {
	z-index: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.active_player_text h1 {
	color: var(--text-primary);
	font-size: 1.2rem;
	letter-spacing: 0.1rem;
	font-weight: normal;
	font-family: 'Snes_Regular';
}


@media (max-width: 834px) {
	.tournament_card {
		width: 100%;
		height: 200px;
	}
	.tournament_card__holder {
		padding: 10px 10px;
	}
	.tournament_card__title {
		font-size: 1.5rem;
	}
	.tournament_card__subtitle {
		font-size: 0.8rem;
	}
	.tournament_card__button {
		width: 70px;
		height: 25px;
		font-size: 0.6rem;
	}
	.fraction > span {
		font-size: 0.8rem;
	}
	.active_player_text h1 {
		font-size: 0.8rem;
	}
}