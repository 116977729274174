.text_container {
	display: flex;
	align-items: flex-end;
	margin-top: 4px;
	gap: 0.3rem;
}

.text_container h1 {
	font-size: 2.3rem;
	margin-top: 2px;
	font-weight: bold;
	color: #f5bc00;
}

.text_container p {
	color: #f4d777;
	font-size: 1rem;
	font-weight: bold;
	margin-bottom: 6px;
}

.user_bp_stats {
	grid-area: user_bp_stats;
	gap: 0.5rem;
	background: var(--cards-effect-background);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
	border-radius: 30px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 0.4rem 0 1.8rem;
}

.user_stat_bp_ctnr {
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

@media screen and (max-width: 1530px) {
	.user_bp_stats {
		justify-content: center;
		padding: 0;
	}

	.user_stat_bp_ctnr {
		display: none;
	}
}

@media screen and (max-width: 1360px) {
	.user_bp_stats {
		height: 230px;
		padding: 0 0.4rem 0 1.3rem;
		justify-content: space-between;
	}

	.user_level_progress_ctnr {
		transform: scale(0.9);
	}

	.user_stat_bp_ctnr {
		display: flex;
	}
}

@media screen and (max-width: 480px) {
	.user_bp_stats {
		justify-content: center;
		padding: 0;
	}
	
	.user_stat_bp_ctnr {
		display: none;
	}
}
