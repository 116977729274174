.chat_not_found {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 0.6rem;
	text-align: center;
	font-weight: normal;
}

.chat_not_found img {
	width: 100%;
	height: 200px;
	object-fit: contain;
}

.chat_not_found h1 {
	margin-top: 2rem;
	font-size: 2rem;
	font-weight: normal;
	color: var(--text-primary);
}

.chat_not_found p {
	font-size: 1rem;
	color: var(--text-secondary);
}

@media only screen and (max-width: 1100px) {
	.chat_not_found {
		display: none;
	}
}
