.chat_contact,
.active_chat {
	max-width: 450px;
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	overflow-y: scroll;
}

.my_profile {
	width: 100%;
	margin: 10px 18px;
	display: flex;
	align-items: center;
	gap: 1rem;
	color: var(--text-primary);
}

.profile_image {
	width: 50px;
	height: 50px;
	object-fit: cover;
	border-radius: 50%;
}

.profile_pic {
	position: relative;
	width: 50px;
	height: 50px;
}

.status {
	position: absolute;
	bottom: 0;
	right: 0;
	border-radius: 50%;
	width: 17px;
	height: 17px;
	background-color: #4caf50;
	border: 2px solid #1b100e;
}

.name_status {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	gap: 0.4rem;
}

.name_status p {
	color: var(--text-secondary);
}

.line_break {
	position: absolute;
	height: calc(100% - 8rem);
	width: 1px;
	background-color: rgba(255, 255, 255, 0.1);
	right: 0;
	top: 4rem;
}

.contact_list {
	width: 100%;
	height: 100%;
}

.search_input_container {
	padding: 0px 20px;
	margin: 0 15px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	border-radius: 50px;
	background: var(--cards-effects-background_surface);
	border: var(--cards-effect-border);
}

.search_input_container img {
	width: 18px;
	height: 18px;
}

.search_input_container input {
	height: 55px;
	width: 100%;
	border: none;
	border-radius: 5px;
	padding: 0 1rem;
	background-color: var(--bg-secondary);
	color: #adb1b7;
	outline: none;
}

.contacts_ul {
	margin-top: 5px;
	padding: 15px;
	display: flex;
	flex-direction: column;
	gap: 0.4rem;
	overflow-y: auto;
}

.contacts_ul::-webkit-scrollbar {
	width: 5px;
	border-radius: 10px;
}

.contacts_ul::-webkit-scrollbar-thumb {
	background-color: rgba(38, 37, 46, 0.4);
	border-radius: 10px;
}

.contacts_ul li {
	width: 100%;
	height: 75px;
	min-height: 75px;
	display: flex;
	align-items: center;
	border-radius: 20px;
}

a {
	text-decoration: none;
	color: var(--text-primary);
}

.contacts_ul {
	width: 100%;
	height: calc(100% - 51px);
}

@media only screen and (max-width: 1100px) {
	.chat_contact {
		width: 100%;
		max-width: 100%;
		height: 100%;
		position: relative;
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}

	.my_profile {
		width: 100%;
		height: 50px;
		margin: 10px 20px;
		display: flex;
		align-items: center;
		gap: 1rem;
		color: var(--text-primary);
	}

	.line_break {
		position: absolute;
		height: calc(100% - 8rem);
		width: 1px;
		background-color: rgba(255, 255, 255, 0.1);
		right: 0;
		top: 4rem;
	}

	.contact_list {
		width: 100%;
		height: 100%;
		padding: 0 15px;
	}

	.search_input_container {
		margin: 0;
	}

	.search_input_container input {
		height: 50px;
		padding: 0 1rem;
	}

	.contacts_ul {
		padding: 20px 0;
		display: flex;
		flex-direction: column;
		gap: 0.4rem;
		overflow-y: auto;
	}

	.contacts_ul::-webkit-scrollbar {
		width: 5px;
		border-radius: 10px;
	}

	.contacts_ul::-webkit-scrollbar-thumb {
		background-color: rgba(38, 37, 46, 0.4);
		border-radius: 10px;
	}

	.contacts_ul li {
		width: 100%;
		height: 75px;
		min-height: 75px;
		display: flex;
		align-items: center;
		border-radius: 20px;
	}

	a {
		text-decoration: none;
		color: var(--text-primary);
	}

	.line_break {
		display: none;
	}

	.active_chat {
		display: none;
	}
}
