/* Reset CSS */
@import url('https://fonts.googleapis.com/css?family=Press+Start+2P&display=swap');

@font-face {
	font-family: 'Product Sans';
	font-style: normal;
	font-weight: normal;
	src: url(../public/assets/fonts/product_sans_regular.ttf);
}

@font-face {
	font-family: 'DroidKufi';
	font-style: normal;
	font-weight: normal;
	src: url(../public/assets/fonts/droid_sans_arabic.ttf);
}

@font-face {
	font-family: 'Sportypo-Reguler';
	font-style: normal;
	font-weight: normal;
	src:
		local('Sportypo-Reguler'),
		url('../public/assets/fonts/sport_typo_regular.ttf') format('woff');
}

@font-face {
	font-family: 'Snes_Regular';
	font-style: normal;
	font-weight: normal;
	src:
		local('Snes_Regular'),
		url('../public/assets/fonts/snes_regular_italic.ttf') format('woff');
}

* {
	-webkit-user-drag: none;
	-khtml-user-drag: none; 
	-moz-user-select: none; 
	-ms-user-select: none; 
	user-select: none; 
	-webkit-user-drag: none;
	margin: 0;
	font-size: 16px;
	padding: 0;
	box-sizing: border-box;
	scrollbar-width: none;
}


/* End Of Reset CSS */

/* ------ start App CSS ------- */

.App {
	width: 100vw;
	height: 100vh;
	position: relative;
	overflow: hidden;
	display: grid;
}

.App.en,
.App.es,
.App.fr {
	font-family: 'Product Sans', sans-serif;
}

.App.en input,
.App.es input,
.App.fr input,
.App.en button,
.App.es button,
.App.fr button {
	font-family: 'Product Sans', sans-serif;
}

.App.ar {
	font-family: 'DroidKufi', sans-serif;
}

.App.ar input,
.App.ar button {
	font-family: 'DroidKufi', sans-serif;
}

.NotLogged.en,
.NotLogged.es,
.NotLogged.fr {
	font-family: 'Product Sans', sans-serif;
}

.NotLogged.en input,
.NotLogged.es input,
.NotLogged.fr input,
.NotLogged.en button,
.NotLogged.es button,
.NotLogged.fr button {
	font-family: 'Product Sans', sans-serif;
}

.NotLogged.ar {
	direction: rtl;
	font-family: 'DroidKufi', sans-serif;
	overflow: hidden;
}

.NotLogged.ar input,
.NotLogged.ar button {
	font-family: 'DroidKufi', sans-serif;
}

/* ------ end App CSS ------- */

:root {
	background-image: url(../public/assets/images/background.webp);
	background-repeat: no-repeat;
	background-size: cover;
	--text-primary: #fff;
	--text-secondary: #9b9b9b;
	--sidebar-width: 100px;
	--navbar-height: 100px;
	--navbar-height-mobile: 80px;

	--search_bar-background: rgba(29, 29, 29, 0.904);

	--cards-background: linear-gradient(
		135deg,
		rgba(39, 38, 38, 0.2),
		rgba(0, 0, 0, 0.1)
	);

	--cards-shadow: 0px 0px 20px 1px rgba(45, 43, 43, 0.3);
	--cards-border: 1px solid rgba(255, 255, 255, 0.3);
	--cards-blur: blur(10px);
	--cards-effect-background: linear-gradient(
		135deg,
		rgba(56, 54, 54, 0.2),
		rgba(255, 255, 255, 0.1)
	);
	--cards-effect-background_secondary: linear-gradient(
		135deg,
		rgb(18 18 18 / 22%),
		rgb(0 0 0 / 25%)
	);
	--cards-effect-background_tertiary: linear-gradient(
		135deg,
		rgba(99, 99, 99, 0.22),
		rgba(141, 141, 141, 0.25)
	);
	--cards-effects-background_surface: #252525;

	--cards-effect-border: 1px solid #383838;
	--cards-effect-selected-border: 1px solid #757575;
	--cards-effect-border-secondary: 1px solid #585858;
	--cards-effect-blur: blur(40px);

	--separator-color: #3d3d3d;
	-webkit-tap-highlight-color: transparent;

	--enteranceAnim: enteranceAnim 0.3s ease-in-out;
}

@keyframes enteranceAnim {
	0% {
		opacity: 0;
		transform: translateY(20px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

:root::after {
	content: '';
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: radial-gradient(
		circle,
		rgba(32, 24, 125, 0.7) 0%,
		rgba(0, 0, 0, 0.9) 40%
	);
	z-index: -1;
}

input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0px 1000px #f0f0ff inset;
}

.light {
	--text-primary: #ff0000;
}

.fs-2rem {
	font-size: 2rem;
}

.fs-1d2rem {
	font-size: 1.2rem;
}

.fs-1d3rem {
	font-size: 1.3rem;
}

.clr-white {
	color: var(--text-primary);
}

.font-weight-light {
	font-weight: lighter;
}

.flex {
	display: flex;
}

.js-sp-btwn {
	justify-content: space-between;
}

@media only screen and (min-width: 600px) {
	.App {
		grid-template-columns: var(--sidebar-width) 1fr;
		grid-template-rows: var(--navbar-height) 1fr;
		grid-template-areas:
			'sidebar navbar'
			'sidebar main';
	}
}

@media only screen and (max-width: 600px) {
	.App {
		grid-template-columns: 1fr;
		grid-template-rows: var(--navbar-height-mobile) 1fr var(--navbar-height-mobile);
		grid-template-areas:
			'navbar'
			'main'
			'sidebar';
	}

	:root::after {
		content: '';
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background: radial-gradient(
			circle,
			rgba(43, 37, 155, 0.9) 0%,
			rgba(0, 0, 0, 1) 47%
		);
		z-index: -1;
	}
}

.border-tl {
	border-top-left-radius: 10px;
}

.border-tr {
	border-top-right-radius: 10px;
}

a {
	text-decoration: none;
}

li {
	list-style: none;
}

/* **************************************************** */
.mtb-10 {
	margin: 10px 0;
}

.mtb-20 {
	margin: 20px 0;
}

.mtb-30 {
	margin: 30px 0;
}

.mb-10 {
	margin-bottom: 10px;
}

.mb-20 {
	margin-bottom: 20px;
}

.mb-30 {
	margin-bottom: 30px;
}

.p-10 {
	padding: 10px !important;
}

.p-20 {
	padding: 20px !important;
}

.p-30 {
	padding: 30px;
}

.pt-10 {
	padding-top: 10px;
}

.pt-20 {
	padding-top: 20px !important;
}

.pt-30 {
	padding-top: 30px;
}

.pb-10 {
	padding-bottom: 10px;
}

.pb-20 {
	padding-bottom: 20px;
}

.pb-30 {
	padding-bottom: 30px;
}

.pl-10 {
	padding-left: 10px;
}

.pl-20 {
	padding-left: 20px;
}

.pl-30 {
	padding-left: 30px;
}

.pr-10 {
	padding-right: 10px;
}

.pr-20 {
	padding-right: 20px;
}

.pr-30 {
	padding-right: 30px;
}

.mxw-50 {
	max-width: 50%;
}

/* **************************************************** */

/* Backgrounds */

.background_primary {
	background: var(--cards-effect-background) !important;
	backdrop-filter: var(--cards-effect-blur) !important;
	box-shadow: var(--cards-effect-shadow) !important;
	border: var(--cards-effect-border) !important;
}

.background_secondary {
	background: var(--cards-effect-background_secondary) !important;
	backdrop-filter: var(--cards-effect-blur) !important;
	box-shadow: var(--cards-effect-shadow) !important;
	border: var(--cards-effect-border) !important;
}

.react-loading-skeleton {
	--base-color: #3e3e3e !important;
	--highlight-color: #646262 !important;
	--animation-duration: 1.2s !important;
	--animation-direction: normal !important;
	--pseudo-element-display: block !important;
	background-color: #404040 !important;
	line-height: normal !important;
}

.text-center {
	text-align: center;
}

.mr-auto {
	margin-right: auto;
}

.ml-auto {
	margin-left: auto;
}
