@media screen and (min-width: 600px) {
	.games {
		grid-area: main;
		height: calc(100vh - var(--navbar-height) - 4rem);
		display: flex;
		align-items: center;
		gap: 1rem;
		overflow: hidden;
		margin: 1rem 1rem 0rem 1rem;
		justify-content: center;
	}
}

@media (max-width: 800px) {
	.gameArea {
		padding: 10px 0px;
	}
}

.gamesContainer {
	height: 100%;
	max-width: 1838px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 30px;
	gap: 0.1rem;
	animation: var(--enteranceAnim);
}

.gamesAreaHolder {
	width: 100%;
	height: 100%;
	padding: 10px;
}

.panel_holder {
	width: 440px;
	min-width: 440px;
	height: 100%;
	padding: 10px 10px 10px 0px;
}

.panel {
	width: 100%;
	height: 100%;
	border-radius: 25px;
	padding: 10px 0 0 0;
	display: flex;
	flex-direction: column;
}

.panel_title {
	font-size: 4rem;
	color: var(--text-primary);
	font-family: 'Snes_Regular';
	
	font-weight: 400;
	text-align: center;
	margin-top: 10px;
}

.panel_subtitle {
	font-size: 2rem;
	color: var(--text-primary);
	font-family: 'Snes_Regular';
	
	font-weight: 400;
	text-align: center;
}

.panel_opponents_vs {
	width: 32px;
	height: 32px;
	border-radius: 50%;
	border: var(--cards-effect-border-secondary);
	background: var(--cards-effects-background_surface);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 2;
}

.panel_opponents_vs p {
	font-size: 1.4rem;
	color: var(--text-primary);
	font-family: 'Snes_Regular';
	
	font-weight: 400;
	margin-top: 6px;
	margin-right: 4px;
}

.panel_button {
	position: relative;
	width: 98%;
	height: 70px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(55, 53, 129, 1);
	border: 1px solid rgba(56, 56, 56, 1);
	color: var(--text-primary);
	font-family: 'Snes_Regular' !important;
	
	font-weight: 400;
	font-size: 2.4rem;
	border-radius: 28px;
	margin: auto auto 4px auto;
	cursor: pointer;
	padding-top: 10px;
	transition: all 0.3s ease-in-out;
	overflow: hidden;
}

.panel_button:hover {
	background: transparent;
	border: 1px solid var(--text-secondary);
}

.panel_button:hover::before {
	animation: shine 1.5s ease-out infinite;
}

.panel_button::before {
	content: '';
	position: absolute;
	width: 100px;
	height: 100%;
	background-image: linear-gradient(
		120deg,
		rgba(255, 255, 255, 0) 30%,
		rgba(255, 255, 255, 0.8),
		rgba(255, 255, 255, 0) 70%
	);
	top: 0;
	left: -100px;
	opacity: 0.6;
}

@keyframes shine {
	0% {
		left: -100px;
	}
	60%,
	to {
		left: 100%;
	}
}

@media screen and (max-width: 1200px) {
	.panel_title,
	.panel_subtitle,
	.logs {
		display: none;
	}
	.panel_button_mobile_container {
		display: flex;
	}
	.panel_opponents_vs {
		margin-top: 0 !important;
	}
	.panel_button {
		display: none;
	}
	.panel {
		border: none !important;
		background: none !important;
		box-shadow: none !important;
		backdrop-filter: none !important;
		height: fit-content;
		padding: 0;
	}
	.gamesContainer {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 70px 1fr 55px;
		grid-template-areas:
			'panel'
			'main'
			'button';
		overflow: scroll;
		padding: 10px 0;
	}

	.gamesAreaHolder {
		grid-area: main;
	}

	.panel_holder {
		grid-area: panel;
		width: 100% !important;
		padding-right: 0;
		padding-left: 0;
		height: fit-content !important;
		min-width: unset;
	}
	.games {
		overflow: scroll;
	}
	.panel_button_mobile {
		display: block !important;
		grid-area: button;
		position: relative;
		height: 70px;
		align-items: center;
		justify-content: center;
		background: rgba(55, 53, 129, 1);
		border: 1px solid rgba(56, 56, 56, 1);
		color: var(--text-primary);
		font-family: 'Snes_Regular' !important;
		
		font-weight: 400;
		font-size: 2.4rem;
		border-radius: 28px;
		margin: 10px;
		cursor: pointer;
		padding-top: 10px;
		transition: all 0.3s ease-in-out;
		overflow: hidden;
	}
}

.panel_button_mobile {
	display: none;
}

.gameArea {
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 25px;
	display: flex;
	padding: 10px 10px;
	flex-direction: column;
	align-items: center;
	background: radial-gradient(circle, rgba(32, 31, 85, 1) 0%, rgba(1, 0, 35, 1) 100%);
	border: 1px solid #636296;
	gap: 1rem;
	overflow: hidden;
}

.gameAreaBanner {
	padding: 14px 0 10px 0px;
	border-radius: 15px;
	max-height: 200px;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	background: #00000033;
	border: var(--cards-effect-border) !important;
	transition: all 0.3s ease-in-out;
	min-width: 267px;
}

.timeHolder {
	margin-right: 60px;
}

.time_remaining {
	font-size: 1.8rem;
	color: var(--text-primary);
	font-family: 'Snes_Regular';
	
	font-weight: 400;
}

.gameAreaBanner .line {
	height: calc(100% - 20px);
	width: 1px;
	background: #636296;
	margin-top: auto;
}

.rounds_holder {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 0 50px 0 70px;
}

.rounds {
	font-size: 5rem !important;
}

.rounds span {
	font-size: 3rem;
	font-family: 'Snes_Regular';
}

.rounds_remaining,
.rounds {
	font-size: 2rem;
	color: var(--text-primary);
	font-family: 'Snes_Regular';
	
	font-weight: 400;
}

.rounds_remaining {
	font-size: 1.8rem;
}

.time {
	font-size: 5rem !important;
}

.time,
.time span {
	font-size: 2.5rem !important;
	color: var(--text-primary);
	font-family: 'Snes_Regular';
	
	font-weight: 400;
	text-align: center;
}

@media screen and (max-width: 600px) {
	.games {
		height: calc(100svh - var(--navbar-height-mobile) * 2);
	}
	.gamesContainer {
		border: none !important;
		overflow: auto;
		border-radius: 0;
	}
	.panel_holder {
		height: fit-content !important;
		min-width: unset;
	}
	.panel_button_mobile {
		height: 55px;
		border-radius: 16px;
	}
	.panel_button_mobile_container {
		height: 55px;
	}

	.time,
	.time span {
		font-size: 1.5rem !important;
		color: var(--text-primary);
		font-family: 'Snes_Regular';
		
		font-weight: 400;
		text-align: center;
	}
}

.gameAreaBannerTicTacToe {
	gap: 1rem;
}

.panel_button_mobile_container {
	display: none;
}
.panel_button_mobile_container {
	position: relative;
	width: 100%;
	height: 70px;
	padding: 0px 10px 10px 10px;
}
