.graph_root_container {
	width: 100%;
	height: fit-content;
	display: flex;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.graph_container {
	width: 100%;
	padding: 1rem 1rem 2.4rem 1rem;
	background: var(--cards-effect-background_secondary);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
	height: 400px;
	margin-top: 30px;
	border-radius: 30px;
	flex-direction: column;
	align-items: center;
	display: flex;
}