.login_prev_usr_btn {
	width: 100%;
	height: 60px;
	border-radius: 90px;
	display: flex;
	align-items: center;
	padding: 0 8px;
	justify-content: space-between;
	color: var(--text-primary);
	font-size: 2.3rem;
	cursor: pointer;
	transition: all 450ms ease-in-out;
	gap: 1rem;
	background: var(--cards-effect-background);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
}

.login_prev_usr_btn:hover {
	box-shadow:
		rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
		rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
	transition: all 300ms ease;
	transform: scale(1.01);
}

.login_prev_usr_btn img {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border: var(--cards-effect-border);
}

.login_prev_usr_btn h1 {
	margin: auto;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding-inline-end: 21px;
}

.login_prev_clear_usr_cntr {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}

.login_prev_clear_usr_cntr > img:nth-child(1) {
	position: absolute;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: #0000008d;
	padding: 12px;
	cursor: pointer;
	transition: all 200ms ease-in-out;
	opacity: 0;
	transform: rotate(45deg);
}

.login_prev_clear_usr_cntr:hover > img:nth-child(1) {
	transform: rotate(90deg);
	opacity: 1;
	transition: all 300ms ease-in-out;
}

/* mobile-specific responsiveness */

@media (max-width: 600px) {
	.login_prev_usr_btn {
		position: relative;
		gap: 0;
	}
}
