.container {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 3rem;
}
.user_level_container h1 {
	color: var(--text-primary);
	font-size: 1rem;
}
.user_level_container p {
	color: rgb(226, 226, 226);
	font-size: 0.8rem;
}
.user_level_container {
	width: 100%;
	height: 30px;
	display: flex;
	padding: 0 0.4rem;
	display: flex;
	flex-direction: column;
	margin-bottom: 1rem;
}
.user_level_progress {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background: black;
	margin: 10px 0 0 0;
	border-radius: 10px;
}

.user_level_progress_bar {
	width: var(--final-width);
	height: 10px;
	border-radius: 10px;
	background: linear-gradient(90deg, rgba(126, 39, 49, 1) 90%, rgba(0, 0, 0, 0) 100%);
	animation: progress 0.8s ease-in-out;
}

@keyframes progress {
	from {
		width: 0;
	}
	to {
		width: var(--final-width);
	}
}

.buttons_container {
	width: 100%;
	height: 100%;
	display: flex;
	max-width: 316px;
	gap: 2rem;
	justify-content: end;
}
.friend_request_btn,
.remove_friend_btn {
	max-width: 150px;
	width: 100%;
	height: 40px;
	display: flex;
	justify-content: space-around;
	align-items: center;
	border-radius: 15px;
	cursor: pointer;
	transition: 0.3s;
	outline: none;
	border: none;
	color: var(--text-primary);
	padding: 0 1rem;
}

.friend_request_btn {
	background-color: #4441ba;
	box-shadow: 0px 0px 20px 0px #4441ba;
}

.remove_friend_btn {
	background-color: #fb4383;
	box-shadow: 0px 0px 20px 0px #fb4383;
}

.button_icon {
	width: 20px;
	height: 20px;
}
.message_btn {
	padding: 0 1rem;
	max-width: 150px;
	width: 100%;
	height: 40px;
	display: flex;
	justify-content: space-around;
	align-items: center;
	color: var(--text-primary);
	border-radius: 15px;
	cursor: pointer;
}
.button_icon {
	width: 20px;
	height: 20px;
}

@media (max-width: 780px) {
	.container {
		flex-direction: column;
		gap: 1rem;
	}
	.buttons_container {
		justify-content: unset;
		max-width: unset;
	}
}
