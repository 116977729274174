.auth_root_container {
	display: flex;
	justify-content: center;
	flex-direction: column;
	width: 90%;
	height: 100%;
	overflow-y: scroll;
	overflow-x: hidden;
	position: absolute;
	top: 0;
	inset-inline-start: -120%;
	padding-inline-start: 80px;
}

.auth_root_container.active {
	inset-inline-start: 0;
	transition: all 0.5s ease-in-out;
}

.auth_root_container.inActive {
	inset-inline-start: -120%;
	transition: all 0.5s ease-in-out;
}

.header_container {
	max-width: 600px;
	gap: 10px;
	display: flex;
	flex-direction: column;
	margin-left: 8px;
}

.header_container h1 {
	font-size: 3rem;
	font-family: 'Sportypo-Reguler';
	font-weight: normal;
}

.header_container h3 {
	font-weight: normal;
}

.logo_container {
	align-items: center;
	display: flex;
	gap: 30px;
	color: var(--text-primary);
	padding-bottom: 30px;
}

.logo_container img {
	transform: translateY(-5px);
}

.auth_form {
	margin-top: 50px;
	max-width: 620px;
	width: 100%;
	padding: 0 10px;
}

.auth_form_content {
	display: flex;
	flex-direction: column;
	gap: 30px;
}

.input_layout_container {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.input_layout_container div {
	padding: 0px 15px 0px 15px;
	align-items: center;
	display: flex;
	justify-content: space-between;
	gap: 20px;
	width: 100%;
	height: 60px;
	border-radius: 90px;
	background-color: #f0f0ff;
}

.input_layout_container label {
	color: var(--text-primary);
	margin: 10px 0px 15px 10px;
}

.input_layout_container input {
	width: 100%;
	background-color: transparent;
	border: transparent;
	outline: none;
	font-size: 1.1rem;
}

.submit_btn {
	background-color: #3b36c5;
	width: 100%;
	height: 60px;
	border-radius: 90px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--text-primary);
	font-size: 2.3rem;
	cursor: pointer;
	transition: all 450ms ease-in-out;
	outline: none;
	border: none;
	margin-top: 10px;
}

.submit_btn:hover {
	background-color: rgb(24, 49, 121);
	box-shadow:
		rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
		rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
	transition: all 300ms ease;
	transform: scale(1.005);
}

.or_divider {
	display: flex;
	align-items: center;
	gap: 10px;
	margin: 30px 0;
	color: white;
}

.or_divider > div:nth-child(1) {
	background: linear-gradient(to right, #ffffff07, #959595);
}

.or_divider > div:nth-child(3) {
	background: linear-gradient(to left, #ffffff07, #959595);
}

.divider_line {
	width: 100%;
	height: 1px;
	border-radius: 90px;
}

.oauth_container {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 1rem;
}

.login_intra42_btn {
	width: 100%;
	height: 60px;
	background-color: var(--text-primary);
	gap: 16px;
	border-radius: 90px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: rgb(255, 255, 255);
	font-size: 2.3rem;
	cursor: pointer;
	transition: all 450ms ease-in-out;
	background: var(--cards-effect-background);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
}

.login_intra42_btn:hover {
	box-shadow:
		rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
		rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
	transition: all 300ms ease;
	transform: scale(1.01);
}

.login_intra42_btn h2 {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.swap_auth_method_btn {
	margin-top: 35px;
	color: var(--text-primary);
	display: flex;
	justify-content: center;
}

.swap_auth_method_btn h2 {
	font-size: 1.1rem;
	cursor: pointer;
	text-align: center;
}

@media (max-width: 1250px) {
	.auth_root_container {
		width: 100%;
		align-items: center;
		padding: 40px 0;
	}

	.logo_container img {
		height: 70%;
	}

	.header_container {
		margin: 0;
		justify-content: center;
		align-items: center;
	}

	.header_container h2 {
		font-size: 1.6rem;
	}

	.header_container h3 {
		font-size: 1.1rem;
	}

	.logo_container {
		gap: 14px;
	}

	.logo_container h1 {
		font-size: 2rem;
	}

	.input_layout_container div {
		background-color: #242530;
	}

	.input_layout_container input {
		color: var(--text-primary);
	}

	.input_layout_container input::placeholder {
		color: #9a999e;
	}
}

/* mobile-specific responsiveness */

@media (max-width: 600px) {
	.auth_root_container {
		padding: 10px 10px 80px 10px;
		justify-content: flex-start;
		align-items: start;
	}

	.header_container {
		margin: 10px 0px 0px 8px;
		align-items: start;
	}

	.header_container h2 {
		font-size: 1.5rem;
	}

	.header_container h3 {
		font-size: 1rem;
	}

	.logo_container {
		padding-bottom: 10px;
	}

	.auth_form {
		margin-top: 25px;
	}

	.auth_form_content {
		gap: 20px;
	}

	.oauth_container {
		flex-direction: column;
	}

	.login_intra42_btn {
		justify-content: space-between;
		padding: 0px 10px 0px 10px;
	}

	.login_intra42_btn h2 {
		margin: auto;
		transform: translateX(-15px);
	}
}

/* componenets */

.switch {
	--secondary-container: #3b36c5;
	--primary: #a8a6e9;
	font-size: 17px;
	position: relative;
	display: inline-block;
	width: 3.7em;
	height: 1.8em;
}

.switch input {
	display: none;
	opacity: 0;
	width: 0;
	height: 0;
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	inset-inline-start: 0;
	right: 0;
	bottom: 0;
	background-color: #313033;
	transition: 0.2s;
	border-radius: 30px;
}

.slider:before {
	position: absolute;
	content: '';
	height: 1.4em;
	width: 1.4em;
	border-radius: 20px;
	inset-inline-start: 0.2em;
	bottom: 0.2em;
	background-color: #aeaaae;
	transition: 0.4s;
}

input:checked + .slider::before {
	background-color: var(--primary);
}

input:checked + .slider {
	background-color: var(--secondary-container);
}

input:focus + .slider {
	box-shadow: 0 0 1px var(--secondary-container);
}

input:checked + .slider:before {
	transform: translateX(1.9em);
}
