.HostTournament {
	width: 100%;
	max-width: 420px;
	height: 100%;
	border-radius: 30px;
	padding: 20px;
	animation: slide_from_left 0.5s ease;
	overflow: scroll;
}

@keyframes slide_from_left {
	0% {
		transform: translateX(100%);
	}
	100% {
		transform: translateX(0);
	}
}

.title_container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 20px;
}

.title_container img {
	cursor: pointer;
}

.title_container h1 {
	font-size: 2rem;
	font-family: 'Snes_Regular';
	font-weight: normal;
	color: var(--text-primary);
}

.banner_selector_container {
	width: 100%;
	height: 100%;
	border-radius: 20px;
	max-height: 150px;
	overflow: hidden;
	scroll-behavior: smooth;
	position: relative;
	border: var(--cards-effect-border);
}

.banner_selector_container::-webkit-scrollbar {
	display: none;
}

.banner_selector_images {
	display: flex;
	overflow: scroll;
	scroll-behavior: smooth;
}

.banner_selector_images::-webkit-scrollbar {
	display: none;
}

.banner_selector_images img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.banner_selector_right img {
	transform: scaleX(-1);
}

.banner_selector_left {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	z-index: 1;
}

.banner_selector_right {
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	z-index: 1;
}

.form_container {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.option_label {
	font-size: 1rem;
	color: #a3a3a3;
	font-weight: normal;
	padding: 25px 10px 10px 8px;
}

.input_box {
	width: 100%;
	height: 60px;
	border-radius: 20px;
	display: flex;
	align-items: center;
	padding-left: 10px;
	background: var(--cards-effect-background_secondary);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
}

.input_box img {
	width: 26px;
}

.input_box input {
	width: 100%;
	height: 100%;
	background: transparent;
	border: none;
	outline: none;
	color: var(--text-primary);
	font-size: 1.2rem;
	padding-left: 15px;
}

.panel_button {
	width: 100%;
	height: 60px;
	justify-content: center;
	background: rgba(55, 53, 129, 1);
	border: 1px solid rgba(56, 56, 56, 1);
	color: var(--text-primary);
	font-family: 'Snes_Regular' !important;
	font-size: 2rem;
	border-radius: 18px;
	cursor: pointer;
	padding-top: 10px;
	transition: all 0.3s ease-in-out;
	margin-top: 2rem;
}

@media screen and (max-width: 1013px) {
	.HostTournament {
		max-width: none !important;
	}
	
}

@media screen and (max-width: 600px) {
	.HostTournament {
		border-radius: 0 !important;
		border: none !important;
		max-width: none !important;
	}
}
