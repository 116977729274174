.not_found {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 0.6rem;
	text-align: center;
	font-weight: normal;
	padding: 0 40px;
}

.not_found .not_found_img {
	width: 100%;
	height: 200px;
	object-fit: contain;
}

.not_found h1 {
	margin-top: 2rem;
	font-size: 2rem;
	font-weight: normal;
	color: var(--text-primary);
}

.not_found p {
	font-size: 1rem;
	color: var(--text-secondary);
}

.not_found span {
	font-size: 2rem;
	font-weight: normal;
}

.not_found button {
	border-radius: 10px;
	background: var(--cards-effect-background_secondary);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: 1px solid #fff;
	color: #fff;
	font-size: 1rem;
	font-weight: bold;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.5rem 1rem;
	margin-top: 20px;
}

.back_arrow {
	width: 26px;
	height: 26px;
	border: 1px solid #fff;
	border-radius: 50px;
	transform: rotate(90deg);
	padding: 4px;
	margin-right: 10px;
}
