.pictures {
	position: relative;
	background-size: cover;
	background-position: center;
	width: 100%;
	height: var(--background-height);
	display: flex;
	border-radius: 20px !important;
	justify-content: center;
	align-items: center;
	/* overflow: hidden; */
}

.cover_picture {
	height: var(--background-height);
	width: 100%;
	position: absolute;
	z-index: 1;
	border-radius: 25px;
	background-size: cover;
}

.profile_picture {
	display: flex;
	width: var(--picture-width);
	height: var(--picture-height);
	border-radius: 160px;
	overflow: hidden;
	position: absolute;
	z-index: 3;
	top: 50%;
	justify-content: center;
	align-items: center;
	box-shadow: rgba(0, 0, 0, 0.8) 0px 12px 50px -10px;
}

.profile_picture_img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 50%;
	position: absolute;
}

.label_item {
	position: absolute;
	cursor: pointer;
	z-index: 3;
	width: var(--picture-width);
	height: var(--picture-height);
	border-radius: 50%;
}

.label_icon {
	display: none;
}

.label_item:hover {
	background-color: rgba(0, 0, 0, 0.5);
	border-radius: 160px;
}

.label_item:hover .label_icon {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 160px;
}

.icon {
	height: 100%;
	width: 50px;
	background: none;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.icon img {
	width: 50%;
	height: 50%;
}

.label_item.background {
	width: 100%;
	height: 100%;
}

.button_container {
	position: relative;
	background-size: cover;
	background-position: center;
	width: 100%;
	height: var(--background-height);
	display: flex;
	border-radius: 25px;
	justify-content: space-between;
}

.edit_button {
	background: linear-gradient(
		270deg,
		rgba(255, 255, 255, 0) 20%,
		rgba(0, 0, 0, 0.6) 100%
	);
	border: none;
	cursor: pointer;
	position: relative;
	z-index: 2;
	height: 100%;
	width: 40%;
	border-radius: 20px 0 0 19px;
}
.edit_button_2 {
	background: linear-gradient(
		90deg,
		rgba(255, 255, 255, 0) 20%,
		rgba(0, 0, 0, 0.6) 100%
	);
	border: none;
	cursor: pointer;
	position: relative;
	z-index: 2;
	height: 100%;
	width: 40%;
	display: flex;
	justify-content: end;
	border-radius: 0 20px 19px 0;
}

.icon_rotate {
	transform: rotate(180deg);
}
