@media only screen and (max-width: 1220px) {
	.panel_opponents_holder {
		margin-top: 0;
	}
	.panel_opponents {
		width: 100%;
	}
}

.panel_opponents_holder {
	width: 100%;
	height: 100px;
	margin-top: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	overflow: hidden;
}

.panel_opponents_holder .line {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 90%;
	border: var(--cards-effect-border-secondary);
	border-width: 0.5px;
	z-index: 1;
}
.panel_opponents_line {
	position: relative;
	height: 60%;
	width: 12px;
	border-radius: 5px;
}

.panel_opponents_line:nth-child(1) {
	left: -6px;
	background: rgba(67, 192, 125, 1);
}

.panel_opponents_line:last-child {
	right: -6px;
	background: rgba(249, 90, 90, 1);
}

.panel_opponents {
	position: relative;
	width: 90%;
	height: 100%;
	border-radius: 25px;
	display: flex;
	align-items: center;
	background: var(--cards-effects-background_surface);
	border: var(--cards-effect-border);
}

.line {
	width: 1px;
	background: #707070;
	margin-top: auto;
}

.panel_opponents_player {
	height: 100%;
	display: flex;
	align-items: center;
}

.panel_opponents_player_image {
	width: 90px;
	height: 90px;
	border-radius: 20px;
	object-fit: cover;
	z-index: 2;
}

.panel_opponents_player_image:first-child {
	margin-left: 4px;
	margin-right: 10px;
	border: var(--cards-effect-border);
}

.panel_opponents_player_image:last-child {
	margin-right: 4px;
	margin-left: 10px;
	border: var(--cards-effect-border);
}

.panel_opponents_player_score {
	font-size: 2.6rem;
	color: var(--text-primary);
	font-family: 'Snes_Regular';
	margin: 6px 0 0 0;
	z-index: 2;
	border: 5px solid var(--cards-effects-background_surface);
	background-color: var(--cards-effects-background_surface);
}

.panel_opponents_vs__holder {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.panel_opponents_vs {
	width: 32px;
	height: 32px;
	border-radius: 50%;
	border: var(--cards-effect-border-secondary);
	background: var(--cards-effects-background_surface);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 2;
}

.panel_opponents_vs p {
	font-size: 1.4rem;
	color: var(--text-primary);
	font-family: 'Snes_Regular';
	
	font-weight: 400;
	margin-top: 6px;
	margin-right: 4px;
}
.panel_opponents_line {
	position: relative;
	height: 60%;
	width: 12px;
	border-radius: 5px;
}

.panel_opponents_line:nth-child(1) {
	left: -6px;
	background: rgba(67, 192, 125, 1);
}

.panel_opponents_line:last-child {
	right: -6px;
	background: rgba(249, 90, 90, 1);
}

@media screen and (max-width: 1200px) {
	.panel_opponents_holder {
		margin-top: 0;
		height: 70px;
	}

	.panel_opponents {
		width: 100%;
		border-radius: 15px;
	}
	.panel_opponents_player_image {
		width: 55px;
		height: 55px;
		border-radius: 15px;
	}
}
