.ProfileSettings_container {
	width: 100%;
	max-width: 440px;
	border-radius: 25px;
	justify-content: center;
	align-items: center;
	position: relative;
	padding: 12px;
	overflow: auto;
	background: var(--cards-effect-background_secondary);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
	margin-left: auto;
}

.ProfilePreviewLabel {
	display: flex;
	width: 100%;
	border-radius: 30px;
	background: var(--cards-effect-background_secondary);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
	justify-content: center;
	align-items: center;
	justify-content: space-between;
	padding: 10px 15px;
}

.ProfilePreviewLabel img {
	height: 22px;
	width: 22px;
}

.ProfilePreviewLabel h1 {
	font-size: 14px;
	font-weight: normal;
	color: white;
	width: 100%;
	text-align: center;
}

.ProfilePreviewPic {
	width: 100%;
	height: 150px;
	margin-top: 10px;
	border-radius: 25px;
	background-size: cover;
	background-position: center;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.ProfilePreviewName {
	width: 100%;
	margin-top: 70px;
	text-align: center;
	font-size: 1.5rem;
	color: white;
	font-weight: normal;
}
.name {
	margin: 0 90px;
	font-size: 1.5rem;
	text-align: center;
	color: white
}

.username {
	font-size: 15px;
	text-align: center;
	color: white
}

.icon {
	height: 100%;
	width: 50px;
	background: none;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.icon img {
	width: 50%;
	height: 50%;
}

.username_parent {
	width: 100%;
}

.info_item input {
	width: 100%;
	background: none;
	border: none;
	outline: none;
	color: var(--text-primary);
	font-size: 16px;
}

.username_field {
	display: inline-flex;
	justify-items: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
	margin-top: 30px;
}

.username_item {
	display: flex;
	width: 100%;
	padding: 18px 0px;
	margin-top: 10px;
	border-radius: 20px;
	background: var(--cards-effect-background_secondary);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);}

.username_item input {
	width: 100%;
	background: none;
	border: none;
	outline: none;
	color: var(--text-primary);
	font-size: 16px;
	padding-left: 8px;
}

.label_item {
	padding: 10px;
	font-size: 16px;
	color: rgba(163, 163, 163, 1);
}

.navDiv {
	display: flex;
	width: 100%;
	justify-content: center;
	margin-top: 20px;
}
.nav {
	width: 100%;
}

.no_data_view {
	width: 100%;
	height: 100%;
	min-height: 400px;
	flex-direction: column;
	display: flex;
	justify-content: center;
	align-items: center;
}

.no_data_view img {
	width: 100px;
	height: 100px;
}

.no_data_view h1 {
	font-size: 18px;
	color: var(--text-primary);
	margin-top: 20px;
}

.no_data_view p {
	font-size: 15px;
	color: var(--text-secondary);
	margin-top: 10px;
}

@media (max-width: 1800px) {
	.name {
		margin: 0 50px;
	}
	.text {
		font-size: 13px;
	}
}

@media (max-width: 1550px) {
	.ProfileSettings_container {
		display: none;
	}
}

/* start history and friends list */

.contact_matches_history_card {
	border-radius: 25px;
	color: var(--text-primary);
	display: flex;
	justify-content: space-between;
	transition: 0.3s;
	overflow-y: auto;
	width: 100%;
	height: 100%;
}

.contact_matches_history_card::-webkit-scrollbar {
	display: none;
}

/* end history and friends list */

.separator {
	width: 1px;
	height: 50px;
	margin: 0px 10px;
	background: var(--separator-color);
	align-self: center;
}

@media (max-width: 1700px) {
	.contact_profile_root {
		transform: translateX(500%);
		transition: 1s;
		width: 0px;
		margin-left: -25px;
	}
}

@media only screen and (max-width: 1100px) {
	.contact_profile_root {
		display: none;
	}
}

.contacts_ul {
	width: 100%;
	height: 100%;
	max-height: 500px;
}

.contact_matches_history_root {
	width: 100%;
}

.contact_user {
	height: 90px;
	display: flex;
	align-items: center;
	padding: 8px 15px;
	transition: 0.3s;
	position: relative;
}

.status_tab_right {
	position: absolute;
	right: 0;
	top: 50;
	width: 5px;
	height: 50%;
	border-radius: 25px 0px 0px 25px;
	transition: 0.3s;
}

.status_tab_left {
	position: absolute;
	left: 0;
	top: 50;
	width: 5px;
	height: 50%;
	border-radius: 0px 25px 25px 0px;
	transition: 0.3s;
}

.history_list_item {
	width: 100%;
	height: 100%;
	border-radius: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: var(--cards-effect-background_secondary);
	-webkit-backdrop-filter: var(--cards-effect-blur);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
	animation: slide-in 0.3s;
}

@keyframes slide-in {
	from {
		transform: translateX(100%);
	}
	to {
		transform: translateX(0);
	}
}

.user_profile_pic {
	width: 95px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
}

.game {
	width: 95px;
	height: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: flex-end;
}

.game_title {
	width: 100%;
}

.user_profile_pic img {
	width: 45px;
	height: 45px;
	border-radius: 50%;
	object-fit: cover;
}

.listDiv {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}

.historyContainer {
	width: 100%;
	height: fit-content;
	border-radius: 20px;
	margin-top: 10px;
}
