@import '../../App.css';

.shimmer_container {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 1rem;
}

.shimmer_view {
	composes: background_secondary from '../../App.css';
	display: flex;
	padding: 10px;
	border-radius: 18px;
	gap: 1rem;
	align-items: center;
}
