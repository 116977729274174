.signupBtn {
	width: 100%;
	max-width: 130px;
	min-width: 130px;
	height: 40px;
	border-radius: 30px;
	border: none;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding-left: 13px;
	gap: 9px;
	color: white;
	position: relative;
	cursor: pointer;
	box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.212);
	font-weight: 600;
}

.arrow {
	position: absolute;
	right: 7.5px;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 30px;
	transition: all 0.3s ease;
}

.signupBtn:hover .arrow {
	width: calc(130px - (7.5px) * 2);
}

.arrow img {
	width: 20px;
}

.all-parent {
	width: 100% !important;
}

.infinite_smooth_spinner {
	animation: infinite_smooth_spinner 1.5s linear infinite;
}

@keyframes infinite_smooth_spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@media (max-width: 600px) {
	.signupBtn {
		width: 100%;
		max-width: 100%;
	}
}
