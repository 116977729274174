.PreviewGameBoy_holder {
	height: 100%;
	display: flex;
	align-items: center;
	transition: all 0.3s ease-in-out;
}

.PreviewGameBoy_frame {
	box-shadow: rgb(0 0 0 / 25%) -9px -9px 10px 5px inset;
	width: 600px;
	height: calc(80vw * 1.2);
	min-width: 600px;
	max-height: 970px;
	min-height: 970px;
	display: flex;
	flex-direction: column;
	border-radius: 25px 25px 100px 25px;
	display: flex;
	background-color: #c9c9c9;
	padding: 20px;
	position: relative;
}

.PreviewGameBoy_screen_frame {
	height: 40%;
	width: 100%;
	border-radius: 25px 25px 100px 25px;
	box-shadow:
		rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
		rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1.5rem;
}

.PreviewGameBoy_screen_frame img {
	width: 100%;
	height: 100%;
}

.PreviewGameBoy_text_holder {
	display: flex;
	flex-direction: column;
	padding: 30px 0px 0px 0px;
}

.PreviewGameBoy_text_holder h1 {
	font-family: 'Press Start 2P', monospace;
	color: #1a1a1a;
	font-size: 2rem;
	margin: 5px 0px 0px 6px;
}

.PreviewGameBoy_text_holder h2 {
	color: #1a1a1a;
	font-weight: normal;
	font-size: 1.3rem;
	margin: 15px 0px 0px 5px;
}

.PreviewGameBoy_top_buttons_holder {
	display: flex;
	padding: 50px 0px 0px 0px;
	justify-content: space-between;
}

.PreviewGameBoy_top_buttons_holder img {
	width: 180px;
}

.PreviewGameBoy_bottom_buttons_holder {
	display: flex;
	position: absolute;
	bottom: 30px;
	right: 30px;
	justify-content: flex-end;
	gap: 80px;
}

/* tablet/mobile responsiveness */

@media (max-width: 1590px) {
	.PreviewGameBoy_holder {
		transform: scale(0.8);
	}
}

@media (max-width: 1250px) {
	.PreviewGameBoy_holder {
		transform: scale(0.7);
	}
}
