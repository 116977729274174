.loading_view {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100%;
	animation: fadeIn 1s forwards;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.logo_progress_holder {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: absolute;
	gap: 1.5rem;
	animation: animateLogo 2s 2s forwards;
	top: 50%;
	transform: translateY(-50%);
}

.logo_progress_holder h1 {
	color: #fff;
	font-size: 2rem;
	margin: 5px 0px 0px 5px;
	font-family: 'Sportypo-Reguler';
	text-align: center;
	font-weight: normal;
}

.logo_holder {
	gap: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.logo_holder img {
	width: 45px;
}

.progress_bar {
	width: 100%;
	height: 6px;
	background-color: #ffffff63;
	border-radius: 8px;
	position: relative;
	overflow: hidden;
	animation: progress_holder 2.5s 1.5s ease-in-out forwards;
}

.progress_bar::before {
	content: '';
	position: absolute;
	background-color: rgb(255, 255, 255);
	width: 0%;
	height: 100%;
	border-radius: 8px;
	animation: progress_before 5s ease-in-out forwards;
}

@keyframes progress_holder {
	0% {
	}
	100% {
		opacity: 0;
	}
}

@keyframes progress_before {
	0% {
		width: 0%;
	}
	100% {
		width: 100%;
	}
}
