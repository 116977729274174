.navbar {
	grid-area: navbar;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 2rem;
	padding-right: 1rem;
	transition: all 0.3s ease-in-out;
}

.dropdown_holder {
	display: flex;
}

.navbar .logo img {
	width: 50px;
	height: 50px;
	transform: translateY(2px);
	display: none;
}

.navbar .logo p {
	word-wrap: break-word;
	color: var(--text-primary);
	font-size: 1.5rem;
	text-align: center;
	font-family: 'Sportypo-Reguler';
	font-weight: normal;
	transition: all 0.3s ease-in-out;
	text-align: left;
}

@media screen and (max-width: 800px) {
	.navbar {
		gap: 0.8rem;
	}
	.navbar .logo p {
		transition: all 0.3s ease-in-out;
		display: none;
	}
}

@media only screen and (max-width: 600px) {
	.navbar {
		gap: 0.8rem;
		padding: 0 0.5rem;
		height: var(--navbar-height-mobile);
		background: var(--cards-effect-background_secondary) !important;
		box-shadow: var(--cards-effect-shadow) !important;
	}
	.navbar .logo img {
		display: inline;
		width: 40px;
		height: 40px;
	}
}
