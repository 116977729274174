.sidebar {
	grid-area: sidebar;
	width: var(--sidebar-width);
	transition: width 0.5s ease-out;
	overflow-y: auto;
	z-index: 1000;
}

.sidebar::-webkit-scrollbar {
	display: none;
}

.sidebar::-webkit-scrollbar-thumb {
	background-color: var(--scrollbar-color);
}

@media (min-width: 600px) {
	.sidebar {
		height: 100vh;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		position: fixed;
		gap: 1rem;
	}

	.item img {
		width: 40px;
		fill: #fff;
	}

	.bg_effect {
		background: radial-gradient(
			circle,
			rgba(66, 62, 201, 0.7) 0%,
			rgba(0, 0, 0, 0) 47%
		);
		transition: all 0.5s;
	}

	.bg_effect img {
		transform: translateX(5px);
		transition: all 0.5s;
	}

	@keyframes selector_animation {
		from {
			transform: scale(0.1);
		}
		to {
			transform: scale(1);
		}
	}

	.item {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		height: var(--navbar-height);
		width: 100%;
	}

	.selector {
		animation: selector_animation 0.5s ease-out;
		position: absolute;
		left: 0;
		height: 50px;
		width: 5px;
		background: rgba(66, 62, 201, 0.7);
		border-radius: 0 5px 5px 0;
		transition: 0.5s;
		box-shadow: 0 0 10px 0 rgba(66, 62, 201, 0.7);
	}

	.item:hover {
		background: radial-gradient(
			circle,
			rgba(66, 62, 201, 0.7) 0%,
			rgba(0, 0, 0, 0) 47%
		);
	}

	.logo {
		display: flex;
		justify-content: center;
		align-items: center;
		height: var(--navbar-height);
		margin: 0 0 auto 0;
	}

	.logo img {
		width: 50px;
	}

	.sidebar {
		background: var(--sidebar-background);
		backdrop-filter: var(--sidebar-blur);
		box-shadow: var(--sidebar-shadow);
	}

	.item:last-child {
		margin-top: auto;
	}

	.item {
		position: relative;
	}
}

@media only screen and (max-width: 600px) {
	.sidebar {
		width: 100vw;
		grid-area: sidebar;
		position: fixed;
		bottom: 0;
	}

	.selector {
		animation: selector_animation 0.5s ease-out;
		position: absolute;
		bottom: 5px;
		height: 5px;
		width: 50%;
		background: rgba(66, 62, 201, 0.7);
		border-radius: 5px 5px 5px 5px;
		box-shadow: 0 0 20px 0 rgba(0, 89, 255, 0.7);
		transition: 0.5s;
	}
	@keyframes selector_animation {
		from {
			transform: scale(0.1);
		}
		to {
			transform: scale(1);
		}
	}
	.item {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		height: var(--navbar-height-mobile);
		width: 100%;
	}

	.item:hover {
		background: radial-gradient(
			circle,
			rgba(66, 62, 201, 0.7) 0%,
			rgba(0, 0, 0, 0) 47%
		);
	}

	.logo {
		display: none;
	}
	.sidebar {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
		height: var(--navbar-height-mobile);
	}
	.sidebar {
		background: var(--cards-effect-background_secondary) !important;
		backdrop-filter: var(--cards-effect-blur) !important;
		box-shadow: var(--cards-effect-shadow) !important;
		/* border-top: var(--cards-effect-border) !important; */
	}
	.item img {
		width: 30px;
		fill: #fff;
	}
}
