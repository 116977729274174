.tictactoe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.tictactoe__board {
	position: relative;
	width: 300px;
	height: 300px;
	transition: 0.5s ease-in-out;
}

.draw {
	position: relative;
	z-index: 3;
	width: 100%;
	height: 100%;
	border-radius: 40px;
}

.blurred > *:not(.exclude-blur) {
	filter: blur(0);
}

.draw__holder {
	position: relative;
	z-index: 4;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.draw p {
	font-size: 8rem;
	color: var(--text-primary);
	font-family: 'Snes_Regular';
	font-weight: 400;
	letter-spacing: 2px;
	animation-name: attention;
	animation-duration: 1s;
	animation-iteration-count: 3;
}

.border_bottom__one,
.border_bottom__two,
.border_top__one,
.border_top__two {
	transition: 0.5s ease-in-out;
	animation: scale_in 0.5s ease-in-out;
}

@keyframes scale_in {
	0% {
		transform: scale(0);
	}

	100% {
		transform: scale(1);
	}
}

@keyframes attention {
	0% {
		transform: scale(1);
		display: block;
	}

	50% {
		transform: scale(1.1);
	}

	100% {
		transform: scale(1);
		display: none;
	}
}

.tictactoe__row {
	display: flex;
	height: calc(100% / 3);
	width: 100%;
}

.tictactoe__cell {
	font-size: 160px;
	border-radius: 2rem;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	border: none;
	background-color: transparent;
	outline: none;
	text-transform: uppercase;
	color: var(--text-primary);
	cursor: pointer;
	caret-color: transparent;
}

.tictactoe__cell img {
	width: 80%;
}

.border_bottom__one {
	position: absolute;
	left: 0;
	top: calc((100% / 3) - 10px);
	width: 100%;
	height: 20px;
	background-color: #ffffffb3;
	border-radius: 30px;
	z-index: 2;
}

.border_bottom__two {
	position: absolute;
	left: 0;
	top: calc(((100% / 3) + (100% / 3)) - 10px);
	width: 100%;
	height: 20px;
	background-color: #ffffffb3;
	border-radius: 30px;
	z-index: 2;
}

.border_top__one {
	position: absolute;
	left: calc((100% / 3) - 10px);
	top: 0;
	width: 20px;
	height: 100%;
	background-color: #ffffffb3;
	border-radius: 30px;
	z-index: 2;
}

.border_top__two {
	position: absolute;
	left: calc(((100% / 3) + (100% / 3)) - 10px);
	top: 0;
	width: 20px;
	height: 100%;
	background-color: #ffffffb3;
	border-radius: 30px;
	z-index: 2;
}

.tictactoe__cell--x {
	color: #f00;
}

.tictactoe__cell--o {
	color: #00f;
}

.before_game_container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 2rem;
	position: absolute;
	top: 130px;
	left: 0;
	overflow: scroll;
}
