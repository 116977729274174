.settings_container {
	grid-area: main;
	height: calc(100vh - var(--navbar-height) - 4rem);
	display: flex;
	justify-content: center;
	padding: 1rem 1rem 0 1rem;
	width: 100%;
}

.settings {
	gap: 1rem;
	display: flex;
	height: calc(100vh - var(--navbar-height) - 4rem);
	background: var(--cards-effect-background);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
	border-radius: 30px;
	max-width: 1836px;
	width: 100%;
	padding: 10px;
	animation: var(--enteranceAnim);
}

.active {
	opacity: 1;
	animation: enterance 0.2s forwards;
	width: 100%;
	max-width: 600px;
}

.inActive {
	opacity: 0;
	position: absolute;
	animation: exit 0.2s forwards;
	pointer-events: none;
	width: 100%;
	max-width: 600px;
}

@keyframes enterance {
	0% {
		opacity: 0;
		transform: translateY(20px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes exit {
	0% {
		opacity: 1;
		transform: translateY(0);
	}
	100% {
		opacity: 0;
		transform: translateY(20px);
	}
}

@media (max-width: 998px) {
	.settings {
		flex-direction: column;
		overflow: auto;
	}

	.settings.open_active_area {
		padding: 0;
	}
}

@media only screen and (max-width: 600px) {
	.settings {
		height: calc(100svh - var(--navbar-height-mobile) * 2);
		background: var(--cards-effect-background);
		backdrop-filter: var(--cards-effect-blur);
		box-shadow: var(--cards-effect-shadow);
		border: none;
		border-radius: 0px;
		padding: 0;
	}
	.settings_container {
		padding: 0;
	}
}
