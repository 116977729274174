.dropdown {
	position: relative;
	width: 240px;
	height: 60px;
	border-radius: 30px;
	z-index: 3;
}

.dropdown__content {
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 30px;
	z-index: 3;
	background: var(--cards-effect-background_secondary);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
	cursor: pointer;
}

.dropdown__content__image__holder {
	position: relative;
	height: 100%;
	display: flex;
	align-items: center;
	margin: auto 10px auto 5px;
}

.dropdown__content__image {
	width: 50px;
	height: 50px;
	object-fit: cover;
	border-radius: 50%;
	border: var(--cards-effect-border);
}

.dropdown__content__status {
	position: absolute;
	top: 2px;
	right: 0px;
	background: #00d315;
	width: 17px;
	height: 17px;
	border-radius: 50%;
	border: 2px solid #0a0d11;
	border-width: 3px;
}

.dropdown__content__text {
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.dropdown__new_notifs_holder {
	display: flex;
	align-items: center;
	gap: 5px;
}

.dropdown__notification_dot {
	background: #ec5d5d;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	transform: translateY(1px);
}

.dropdown__content__title {
	font-size: 0.95rem;
	max-width: 125px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: var(--text-primary);
}

.dropdown__content__subtitle {
	font-size: 0.8rem;
	color: var(--text-primary);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.dropdown__content__arrow__holder {
	height: 30px;
	width: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: auto 10px auto auto;
	border-radius: 50%;
	background: transparent;
	border: var(--cards-effect-border);
	user-select: none;
}

.dropdown__content__arrow {
	width: 20px;
	user-select: none;
	transition: all 0.5s ease;
}

.dropdown__content__arrow__holder .dropdown__content__arrow.showDropDown {
	transform: rotate(180deg);
	transition: all 0.5s ease;
}

.dropdown__holder {
	position: absolute;
	top: 50%;
	left: 0;
	height: 0;
	width: 100%;
	z-index: 2;
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
	transition: all 0.5s ease;
	overflow: hidden;
	border: var(--cards-effect-border);
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
}

.dropdown__holder.active {
	height: 204px;
	transition: all 0.5s ease;
}

@media only screen and (max-width: 600px) {
	.dropdown {
		position: inherit;
		width: auto;
		height: 50px;
	}

	.dropdown__content {
		position: relative;
		width: 50px;
		height: 50px;
		border-radius: 50%;
		overflow: hidden;
		border: var(--cards-effect-border);
		border-width: 2px;
	}

	.dropdown__content__image__holder {
		margin: 0;
	}

	.dropdown__content__image,
	.dropdown__content__image__holder {
		width: 100%;
		height: 100%;
	}

	.dropdown .dropdown__content__title,
	.dropdown .dropdown__content__subtitle,
	.dropdown__content__status {
		display: none;
	}

	.dropdown .dropdown__content__arrow__holder {
		opacity: 0;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}

	.dropdown .dropdown__content__arrow__holder img {
		-webkit-user-drag: none;
		user-select: none;
		-moz-user-select: none;
		-webkit-user-select: none;
		-ms-user-select: none;
	}

	.dropdown__new_notifs_holder {
		display: none;
	}
}
