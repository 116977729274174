/* -----> MAIN COMPONENT <-----
-----> right side - gameboy <-----
*/

.gameboy_holder {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 50%;
	transition: all 0.3s ease-in-out;
	padding-inline-end: 70px;
	user-select: none;
}

.gameboy_frame {
	box-shadow: rgb(0 0 0 / 25%) -9px -9px 10px 5px inset;
	width: 600px;
	height: calc(80vw * 1.2);
	min-width: 600px;
	max-height: 970px;
	min-height: 970px;
	display: flex;
	flex-direction: column;
	border-radius: 25px 25px 100px 25px;
	display: flex;
	background-color: #c9c9c9;
	padding: 20px;
	position: relative;
}

.gameboy_screen_frame {
	height: 40%;
	width: 100%;
	border-radius: 25px 25px 100px 25px;
	box-shadow:
		rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
		rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}

.frameVideo {
	background-color: #000000;
	top: 0;
	left: 0;
	margin: 0px 0px 0px 0px;
	width: 100%;
	height: 100%;
	border-radius: 25px 25px 100px 25px;
}

.frameVideo video {
	border-radius: 25px 25px 100px 25px;
}

.start_button_container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	position: relative;
}

.start_button {
	position: absolute;
	top: 0;
	right: 0;
	width: 45px;
	height: 16px;
	background: rgba(255, 0, 0, 0.121);
	border: none;
	outline: none;
	border-radius: 121px;
	transform: rotate(142deg) translate(25px, 4px);
	border: 2px solid;
	cursor: pointer;
	opacity: 0.4;
	animation: border-color-animation 2s infinite;
}

.pause_button {
	position: absolute;
	top: 0;
	left: 0;
	width: 45px;
	height: 16px;
	background: rgba(255, 0, 0, 0.121);
	border: none;
	outline: none;
	cursor: pointer;
	border-radius: 121px;
	transform: rotate(143deg) translate(7px, -10px);
	border: 2px solid;
	opacity: 0.4;
	animation: border-color-animation 2s infinite;
}

@keyframes border-color-animation {
	0% {
		border-color: #3498db;
	}
	33% {
		border-color: #e74c3c;
	}
	66% {
		border-color: #2ecc71;
	}
	100% {
		border-color: #3498db;
	}
}

.gameboy_text_holder {
	display: flex;
	flex-direction: column;
	padding: 30px 0px 0px 0px;
}

.gameboy_text_holder h1 {
	font-family: 'Press Start 2P', monospace;
	color: #1a1a1a;
	font-size: 2rem;
	margin: 5px 0px 0px 5px;
}

.gameboy_text_holder h2 {
	color: #1a1a1a;
	font-weight: normal;
	font-size: 1.3rem;
	margin: 15px 5px 0px 5px;
}

.gameboy_top_buttons_holder {
	display: flex;
	padding: 50px 0px 0px 0px;
	justify-content: space-between;
}

.gameboy_top_buttons_holder img {
	width: 180px;
}

.gameboy_bottom_buttons_holder {
	display: flex;
	position: absolute;
	bottom: 30px;
	right: 30px;
	justify-content: flex-end;
	gap: 80px;
}

/* tablet/mobile responsiveness */

@media (max-width: 1590px) {
	.gameboy_holder {
		transform: scale(0.8);
		padding-right: 0px;
		transition: all 0.3s ease-in-out;
	}
}

@media (max-width: 1250px) {
	.gameboy_holder {
		display: none;
		visibility: hidden;
		transition: all 0.3s ease-in-out;
	}
}

.notStarted {
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	gap: 3rem;
	border-radius: 25px 25px 100px 25px;
	z-index: 100;
}

.notStarted h1 {
	color: #fff;
	font-size: 2rem;
	margin: 5px 0px 0px 5px;
	font-family: 'Sportypo-Reguler';
	text-align: center;
	font-weight: normal;
}

.start_switch_holder {
	position: absolute;
	bottom: 12px;
	opacity: 0;
	animation: showSwitch 2s 3.5s forwards;
}

.logo_progress_holder {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: absolute;
	gap: 1.5rem;
	animation: animateLogo 2s 2s forwards;
	top: 50%;
	transform: translateY(-50%);
}

.logo_holder {
	gap: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.logo_holder img {
	width: 45px;
}

.progress_bar {
	width: 100%;
	height: 6px;
	background-color: #ffffff63;
	border-radius: 8px;
	position: relative;
	overflow: hidden;
	animation: progress_holder 2.5s 1.5s ease-in-out forwards;
}

.progress_bar::before {
	content: '';
	position: absolute;
	background-color: rgb(255, 255, 255);
	width: 0%;
	height: 100%;
	border-radius: 8px;
	animation: progress_before 3.5s ease-in-out forwards;
}

@keyframes progress_holder {
	0% {
	}
	100% {
		opacity: 0;
	}
}

@keyframes progress_before {
	0% {
		width: 0%;
	}
	30% {
		width: 30%;
	}
	50% {
		width: 50%;
	}
	100% {
		width: 100%;
	}
}

@keyframes animateLogo {
	0% {
		top: 50%;
		transform: translateY(-50%);
		scale: 1;
	}
	40% {
		top: 50%;
		transform: translateY(-50%);
		scale: 1.2;
	}
	60% {
		top: 50%;
		transform: translateY(-50%);
		scale: 1;
	}
	100% {
		top: 55px;
		scale: 1;
	}
}

@keyframes showSwitch {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.gameboy_arrow_container {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.left_arrow_button {
	position: absolute;
	left: 0;
	top: 50%;
	transform: translate(40%, -50%);
	width: 46px;
	height: 48px;
	background: none;
	outline: none;
	border: none;
	cursor: pointer;
	border-radius: 7px;
	transition: all 0.1s ease-in-out;
}

.right_arrow_button {
	position: absolute;
	right: 0;
	top: 50%;
	border: none;
	transform: translate(-45%, -50%);
	width: 46px;
	height: 48px;
	background: none;
	outline: none;
	cursor: pointer;
	border-radius: 7px;
	transition: all 0.1s ease-in-out;
}

.up_arrow_button {
	position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-52%, 44%);
    width: 43.5px;
    height: 46px;
    background: none;
    outline: none;
    cursor: pointer;
    border: none;
    border-radius: 7px;
    transition: all 0.1s ease-in-out;
}

.down_arrow_button {
	position: absolute;
	left: 50%;
	bottom: 0;
    transform: translate(-51%, -45%);
    width: 43.5px;
    height: 46px;
	background: none;
	outline: none;
	cursor: pointer;
	border: none;
	border-radius: 7px;
	transition: all 0.1s ease-in-out;
}

.right_arrow_button:hover {
	background-color: #515151;
}

.left_arrow_button:hover {
	background-color: #515151;
}

.up_arrow_button:hover {
	background-color: #515151;
}

.down_arrow_button:hover {
	background-color: #515151;
}

.press_start_text {
	text-align: center;
	color: white;
	max-width: 340px;
	line-height: 2.9rem;
	text-align: center;
	font-size: 2rem;
	margin-top: 34px;
	font-family: 'Press Start 2P', monospace;
	animation: blink 1s infinite;
}

@keyframes blink {
	0% {
		scale: 1;
	}
	50% {
		scale: 1.3;
	}
	100% {
		scale: 1;
	}
}

.switch_game_text {
	margin-top: 104px;
	text-align: center;
	color: white;
	max-width: 340px;
	text-align: center;
	font-size: 1rem;
	direction: ltr;
	font-family: 'Press Start 2P', monospace;
}

.switch_game_text span:nth-child(1) {
	font-family: 'Product Sans', sans-serif;
	transform: translate(-37px, -2px);
	position: absolute;
	animation: animateArrow1 1s infinite;
}

.switch_game_text span:nth-child(2) {
	font-family: 'Product Sans', sans-serif;
	transform: translate(20px, -5px);
	position: absolute;
	animation: animateArrow2 1s infinite;
}

@keyframes animateArrow1 {
	0% {
		transform: translate(-37px, -2px);
	}
	50% {
		transform: translate(-45px, -2px);
	}
	100% {
		transform: translate(-37px, -2px);
	}
}

@keyframes animateArrow2 {
	0% {
		transform: translate(20px, -5px);
	}
	50% {
		transform: translate(30px, -5px);
	}
	100% {
		transform: translate(20px, -5px);
	}
}

.vibrate {
	animation: vibrate 0.35s linear;
}

@keyframes vibrate {
	8%,
	41% {
		transform: translateX(-17px);
	}
	25%,
	58% {
		transform: translateX(17px);
	}
	75% {
		transform: translateX(-7px);
	}
	92% {
		transform: translateX(7px);
	}
	0%,
	100% {
		transform: translateX(17px);
	}
}
