.root_container {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 100vh;
}

.content_container {
	width: 100%;
	height: 100%;
	max-width: 2200px;
	display: flex;
}

.authentication_holder {
	position: relative;
	width: 100%;
	height: 100%;
}

@media screen and (max-width: 1250px) {
	.authentication_holder {
		padding: 0px 12px;
	}
}

@media screen and (max-width: 600px) {
	.authentication_holder {
		padding: 0px 4px;
	}
}