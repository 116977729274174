.logs {
	width: 100%;
	height: calc(100% - 330px);
	margin-top: 20px;
	overflow: auto;
}
.logs::-webkit-scrollbar {
	display: none;
}
.logs_title {
	font-size: 1.2rem;
	color: var(--text-primary);
	padding: 10px 20px;
}
.logs_title {
	font-size: 1.2rem;
	color: var(--text-primary);
	padding: 10px 20px;
}

.logs_holder {
	width: 100%;
	/* height: 50px; */
	display: flex;
	margin-top: 10px;
	flex-direction: column;
}

.logs_log_holder {
	display: flex;
	align-items: center;
	height: 55px;
	overflow: hidden;
	width: 100%;
	margin-bottom: 10px;
}

.logs_log_line {
	position: relative;
	left: -6px;
	width: 10px;
	height: 60%;
	background: #f95a5a;
	border-radius: 5px;
}

.logs_log {
	height: 100%;
	width: 96%;
	background: var(--cards-effects-background_surface);
	border: 1px solid rgba(56, 56, 56, 1);
	border-radius: 15px;
	display: flex;
	align-items: center;
	color: var(--text-primary);
	gap: 5px;
}

.logs_log_image {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	margin: 0px 6px;
}
.panel_button {
	position: relative;
	width: 98%;
	height: 70px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(55, 53, 129, 1);
	border: 1px solid rgba(56, 56, 56, 1);
	color: var(--text-primary);
	font-family: 'Snes_Regular' !important;
	
	font-weight: 400;
	font-size: 2.4rem;
	border-radius: 28px;
	margin: auto auto 4px auto;
	cursor: pointer;
	padding-top: 10px;
	transition: all 0.3s ease-in-out;
	overflow: hidden;
}

.panel_button:hover {
	background: transparent;
	border: 1px solid var(--text-secondary);
}

.panel_button:hover::before {
	animation: shine 1.5s ease-out infinite;
}

.panel_button::before {
	content: '';
	position: absolute;
	width: 100px;
	height: 100%;
	background-image: linear-gradient(
		120deg,
		rgba(255, 255, 255, 0) 30%,
		rgba(255, 255, 255, 0.8),
		rgba(255, 255, 255, 0) 70%
	);
	top: 0;
	left: -100px;
	opacity: 0.6;
}

@media screen and (max-width: 1200px) {
	.logs {
		display: none;
	}
	.panel_button {
		height: 55px;
	}
}
