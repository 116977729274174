.chat_messages_root,
.active_chat {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: flex-end;
	position: relative;
	transition: 0.3s;
	overflow-y: scroll;
}

.chat_message_card {
	width: 100%;
	height: 100%;
	border-radius: 30px;
	background: var(--cards-effect-background_secondary);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
	padding: 10px;
	position: relative;
	transition: 0.3s;
}

.chat_incoming_user_info {
	width: 100%;
	display: flex;
	align-items: center;
	padding: 5px 4px 5px 7px;
	background: var(--cards-effect-background_secondary);
	border-radius: 70px;
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
	justify-content: space-between;
	position: relative;
	z-index: 4;
}

.chat_incoming_user_status {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 8px;
	color: var(--text-primary);
	margin-left: 4.2rem;
}

.chat_incoming_user_status h1 {
	font-size: 1.2rem;
	font-weight: 500;
	color: var(--text-primary);
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.chat_incoming_user_status p {
	font-size: 1rem;
	color: #a3a3a3;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.chat_incoming_user_loading {
	display: flex;
	gap: 12px;
	padding-left: 4px;
}

.chat_incoming_user_loading div {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 6px;
}

.chat_close_box {
	width: 60px;
	height: 60px;
	display: flex;
	justify-content: center;
	background: var(--cards-effect-background_secondary);
	border-radius: 60px;
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
	right: 15px;
}

.chat_close_box img {
	width: 25px;
	height: 25px;
	object-fit: cover;
	margin: auto;
	cursor: pointer;
	opacity: 0.8;
}

/* chat messages start */

.chat_messages {
	height: calc(100% - 140px);
	display: flex;
	flex-direction: column-reverse;
	overflow-y: scroll;
	width: 100%;
	padding-top: 1rem;
}

.blocked {
	pointer-events: none;
	-webkit-filter: blur(6px);
	-moz-filter: blur(6px);
	-o-filter: blur(6px);
	-ms-filter: blur(6px);
	filter: blur(6px);
}

.locked_messages_container {
	width: 100%;
	height: calc(100% - 60px);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	position: absolute;
}

.locked_messages_icon {
	width: 100%;
	height: 150px;
	object-fit: contain;
}

.locked_messages_texts {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 0.4rem;
}

.locked_messages_texts h1 {
	margin-top: 2rem;
	font-size: 1.7rem;
	font-weight: normal;
	color: var(--text-primary);
}

.locked_messages_texts p {
	font-size: 1rem;
	text-align: center;
	color: var(--text-secondary);
}

.chat_messages::-webkit-scrollbar {
	display: none;
}

.chat_message {
	display: flex;
	flex-direction: column;
	align-items: self-end;
	margin-right: auto;
	margin-bottom: 10px;
}

.chat_message_text {
	padding: 14px;
	color: var(--text-primary);
	background: var(--cards-effect-background_secondary);
	border-radius: 30px;
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
	margin-right: auto;
}

.chat_message_text p {
	word-break: break-all;
}

.typing_animation {
	padding: 14px;
	color: var(--text-primary);
	background: var(--cards-effect-background_secondary);
	border-radius: 30px;
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
	margin-right: auto;
	animation: enterance 0.2s ease-in-out;
}

@keyframes enterance {
	0% {
		opacity: 0;
		transform: translateY(20px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

.chat_message_time {
	font-size: 0.8rem;
	margin: 10px 5px;
	color: #888888;
}

.chat_message.participant {
	margin-left: auto;
	margin-right: 0;
}

.chat_message.participant .chat_message_text {
	background-color: #433ec983;
	margin-left: auto;
	margin-right: 0;
}

/* chat messages end */

.incoming_user_pfp {
	width: 55px;
	height: 55px;
	border-radius: 70px;
	position: absolute;
	object-fit: cover;
	border: 2px solid var(--cards-effect-border);
}

/* start input box */
.message_input_box {
	width: 100%;
	height: 70px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	bottom: 14px;
	left: 10px;
	padding: 0px 15px;
	background: var(--cards-effect-background_tertiary);
	border-radius: 60px;
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
}

.message_input_box form {
	width: 100%;
	height: 100%;
}

.message_input_box .not_friend {
	font-size: 1rem;
	font-weight: bold;
	color: rgb(219, 60, 60);
	margin: 0 auto;
	cursor: default;
}

.message_input_field {
	width: 100%;
	height: 100%;
	padding: 0px 10px;
	border: none;
	background: transparent;
	color: var(--text-primary);
	font-size: 1.1rem;
	outline: none;
	cursor: text;
}

.message_send_button {
	cursor: pointer;
}

.chat_message_card.newMessage {
	color: var(--text-primary);
}

.load_more_btn {
	cursor: pointer;
	border-radius: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	align-self: center;
	background: var(--cards-effect-background_tertiary);
	border-radius: 60px;
	padding: 6px 18px;
	margin: 1rem 0;
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
	gap: 0.7rem;
}

.load_more_btn h1 {
	font-size: 1rem;
	font-weight: normal;
	color: var(--text-primary);
}

.load_more_btn img {
	width: 25px;
	transform: rotate(180deg);
}

/* end input box */

@media (max-width: 1900px) {
	.chat_message_card {
		transition: 0.3s;
	}
}

@media (max-width: 1700px) {
	.chat_message_card {
		transition: 0.3s;
		margin-right: 10px;
	}
}

@media only screen and (max-width: 1100px) {
	.chat_messages_root {
		display: none;
	}

	.active_chat {
		width: 100%;
	}

	.chat_message_card {
		background: var(--cards-effect-background_tertiary);
	}

	.incoming_user_pfp {
		cursor: pointer;
	}
}

@media screen and (max-width: 600px) {
	.chat_message_card {
		margin-right: 0px;
		border-radius: 0px;
		border: none;
	}

	.incoming_user_pfp {
		width: 50px;
		height: 50px;
	}

	.chat_close_box {
		width: 50px;
		height: 50px;
	}

	.chat_close_box img {
		width: 18px;
		height: 18px;
	}

	.chat_incoming_user_status {
		margin-left: 3.7rem;
		gap: 5px;
	}

	.chat_incoming_user_status h1 {
		font-size: 1rem;
		font-weight: 500;
		color: var(--text-primary);
	}

	.chat_incoming_user_status p {
		font-size: 0.8rem;
		color: #a3a3a3;
	}

	.message_input_box {
		height: 60px;
		padding: 0px 10px;
	}

	.chat_message_card {
		padding-bottom: 0;
	}

	.chat_message_text p {
		font-size: 0.9rem;
	}

	.chat_message_time {
		font-size: 0.7rem;
		margin: 10px 5px 10px 4px;
	}
}

.lds_spinner,
.lds_spinner div,
.lds_spinner div:after {
	box-sizing: border-box;
}
.lds_spinner {
	color: #fff;
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
	margin: auto;

}
.lds_spinner div {
	transform-origin: 40px 40px;
	animation: lds_spinner 1.2s linear infinite;
}
.lds_spinner div:after {
	content: ' ';
	display: block;
	position: absolute;
	top: 3.2px;
	left: 36.8px;
	width: 6.4px;
	height: 17.6px;
	border-radius: 20%;
	background: currentColor;
}
.lds_spinner div:nth-child(1) {
	transform: rotate(0deg);
	animation-delay: -1.1s;
}
.lds_spinner div:nth-child(2) {
	transform: rotate(30deg);
	animation-delay: -1s;
}
.lds_spinner div:nth-child(3) {
	transform: rotate(60deg);
	animation-delay: -0.9s;
}
.lds_spinner div:nth-child(4) {
	transform: rotate(90deg);
	animation-delay: -0.8s;
}
.lds_spinner div:nth-child(5) {
	transform: rotate(120deg);
	animation-delay: -0.7s;
}
.lds_spinner div:nth-child(6) {
	transform: rotate(150deg);
	animation-delay: -0.6s;
}
.lds_spinner div:nth-child(7) {
	transform: rotate(180deg);
	animation-delay: -0.5s;
}
.lds_spinner div:nth-child(8) {
	transform: rotate(210deg);
	animation-delay: -0.4s;
}
.lds_spinner div:nth-child(9) {
	transform: rotate(240deg);
	animation-delay: -0.3s;
}
.lds_spinner div:nth-child(10) {
	transform: rotate(270deg);
	animation-delay: -0.2s;
}
.lds_spinner div:nth-child(11) {
	transform: rotate(300deg);
	animation-delay: -0.1s;
}
.lds_spinner div:nth-child(12) {
	transform: rotate(330deg);
	animation-delay: 0s;
}
@keyframes lds_spinner {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
