.dropdown__menu {
	position: absolute;
	top: 50%;
	left: 0;
	height: 0;
	width: 100%;
	z-index: 2;
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
	transition: all 0.5s ease;
	overflow: hidden;
	background: var(--cards-effect-background_secondary);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
}

.dropdown__menu.active {
	height: 204px;
	transition: all 0.5s ease;
}

.dropdown__menu__holder {
	margin-top: 30px;
	padding: 4px 4px 0 4px;
	height: 174px;
	width: 100%;
}

.dropdown__menu__item {
	height: 50px;
	width: 100%;
	display: flex;
	align-items: center;
	color: var(--text-primary);
	border-radius: 10px;
	margin-bottom: 6px;
	cursor: pointer;
}

.dropdown__menu__item:hover {
	background-color: #6161617c;
}

.dropdown__menu__item__icon {
	width: 20px;
	height: 20px;
	margin: 0 10px;
}

@media only screen and (max-width: 600px) {
	.dropdown__menu {
		top: 100px;
		right: -120%;
		left: auto;
		width: 76%;
		margin: 0 auto;
		background-color: black;
		border-radius: 20px;
		height: 200px;
	}

	.dropdown__menu.active {
		right: 1rem;
		height: 200px;
	}

	.dropdown__menu__holder {
		margin-top: 0;
		height: 200px;
	}

	.dropdown__menu__item {
		border-radius: 30px;
		height: 60px;
	}
}
