.before_game_container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 2rem;
	position: absolute;
	top: 130px;
	left: 0;
	overflow: scroll;
}

.game_canvas {
	width: 100%;
	height: 100%;
	background: transparent;
}

.ping_pong__game_area__game {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
	background-size: cover;
	background-position: center;
}

.container {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	position: relative;
	justify-content: center;
	margin: auto 0;
}

.canvas {
	transition: transform 0.5s;
	max-width: 1080px;
	max-height: 720px;
}

@media screen and (max-width: 720px) {
	.canvas {
		transform: rotate(90deg);
		transition: transform 0.5s;
	}
}