.friend_item_container {
	display: flex;
	border-radius: 50px;
	background: var(--cards-effect-background_secondary);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
	margin: 10px 0;
	overflow: hidden;
}

.selected {
	background: linear-gradient(
		90deg,
		rgba(255, 217, 0, 0.5) 0%,
		rgba(124, 100, 55, 0.5) 80%
	);
	background-size: 200% 200%;
	animation: gradient 2s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.animation {
	animation: slide_from_left 0.5s ease;
}

.reverse_animation {
	animation: slide_from_right 0.5s ease;
}

@keyframes slide_from_right {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(200%);
	}
}

@keyframes slide_from_left {
	0% {
		transform: translateX(100%);
	}
	100% {
		transform: translateX(0);
	}
}

.friend_profile_img {
	width: 55px;
	height: 55px;
	border-radius: 80%;
	margin: 5px;
	object-fit: cover;
	border: var(--cards-effect-border);
	cursor: pointer;
}

.friend_info_container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-left: 5px;
	gap: 5px;
	cursor: pointer;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.friend_info_container h1 {
	font-size: 1.08rem;
	font-weight: 600;
	color: var(--text-primary);
	margin: 0;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.friend_info_container p {
	font-size: 0.85rem;
	color: #d2d2d2;
	margin: 0;
}

.friend_action_container {
	display: flex;
	align-items: center;
	gap: 5px;
	margin-left: auto;
	margin-right: 5px;
	transition: all 0.5s ease-in-out;
}

.friend_action_container img {
	width: 50px;
	height: 50px;
	cursor: pointer;
	background: var(--cards-effect-background_secondary);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
	border-radius: 70%;
	object-fit: scale-down;
}

.dropdown__content__status {
	position: absolute;
	bottom: 5px;
	left: 44px;
	background: #00d315;
	width: 17px;
	height: 17px;
	border-radius: 50%;
	border: 2px solid #212224;
	border-width: 3px;
}

.message_button_container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.unseen_message_count {
    position: absolute;
    top: 2px;
    right: 2px;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 4px 8px;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 10px;
    min-height: 10px;
}