.Tournaments {
	grid-area: main;
	transition: all 0.3s;
	height: calc(100vh - var(--navbar-height) * 2);
	display: flex;
	align-items: center;
	gap: 1rem;
	overflow: hidden;
	margin: 1rem 1rem 0rem 1rem;
	justify-content: center;
}

@media only screen and (max-width: 600px) {
	.Tournaments {
		margin: 0;
		overflow: scroll;
		height: calc(100svh - var(--navbar-height-mobile) * 2);
	}
}
