.backgroundContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 20px;
}

.backgroundSelector {
	width: 100%;
	height: 200px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 4rem;
}

.bg1 {
	background: radial-gradient(circle, rgba(32, 31, 85, 1) 0%, rgba(1, 0, 35, 1) 100%);
}

.bg2 {
	background: radial-gradient(circle, rgba(85, 37, 31, 1) 14%, rgba(35, 0, 0, 1) 100%);
	/* border: 1px solid #636296; */
}

.bg3 {
	background: radial-gradient(circle, rgba(31, 85, 36, 1) 0%, rgba(0, 35, 4, 1) 100%);
	/* border: 1px solid #636296; */
}

.bg1,
.bg2,
.bg3 {
	width: 35%;
	height: 90%;
	border-radius: 35px;
	cursor: pointer;
	border: 1px solid #383838;
}

.selected {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.149);
	overflow: hidden;
	border-radius: 35px;
	border: 2px solid #ffffffca;
}

/* responsiveness */

@media (max-width: 1600px) {
	.backgroundSelector {
		gap: 20px;
	}
}

@media (max-width: 1400px) {
	.backgroundSelector {
		height: 150px;
	}
}

@media (max-width: 600px) {
	.backgroundSelector {
		height: 100px;
	}

	.bg1,
	.bg2,
	.bg3 {
		border: 2px solid #ffffffca;
		width: 35%;
		height: 80%;
		border-radius: 25px;
		cursor: pointer;
		border: 1px solid #383838;
	}

	.selected {
		border-radius: 25px;
	}

	.selected img {
		width: 30%;
		height: 30%;
	}
}
