.TournamentSelection {
	width: 100%;
	height: 100%;
	background: var(--cards-effect-background);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
	display: flex;
	align-items: center;
	flex-direction: column;
	border-radius: 30px;
	overflow-y: auto;
	max-width: 1400px;
	animation: var(--enteranceAnim);
}

.TournamentSelection__banner {
	width: calc(100% - 40px);
	height: 100px;
	min-height: 300px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	user-select: none;
}

.TournamentSelection__banner__overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(90deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%);
}

.TournamentSelection__activeTournaments {
	width: calc(100% - 40px);
	height: fit-content;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
	gap: 20px;
	padding: 20px 0px;
}

.TournamentSelection__title {
	color: var(--text-primary);
	font-size: 1.6rem;
	width: 100%;
	padding: 20px 20px 20px 20px;
}

.TournamentSelection__active_title {
	color: var(--text-primary);
	font-size: 1.4rem;
}

.TournamentSelection__activePlayers {
	margin-left: 30px;
	z-index: 100;
}

.TournamentSelection__container {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}

.TournamentSelection__activePlayers__circle {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: #3aff7d;
	box-shadow: inset 0px 0px 10px 0px rgba(4, 101, 43, 0.5);
	margin-right: 10px;
}

.TournamentSelection__hostTournament {
	display: flex;
	align-items: flex-end;
	height: 100%;
	z-index: 100;
	padding: 10px;
}

.TournamentSelection__hostButton {
	display: flex;
	gap: 15px;
	padding: 15px 25px;
	background-color: #4441ba;
	border-radius: 15px;
	box-shadow: 0px 0px 20px 0px #4441ba;
	cursor: pointer;
	transition: 0.3s;
}

.TournamentSelection__hostButton:hover {
	background-color: #3a37a0;
	transform: scale(1.02);
	transition: all 0.3s ease-in-out;
}

.TournamentSelection__hostButton h1 {
	color: #d6d4e7;
	font-size: 1rem;
}

.tournament_title {
	color: var(--text-primary);
	font-size: 4rem;
	font-weight: normal;
	font-family: 'Snes_Regular';
}

@media screen and (max-width: 1013px) {
	.TournamentSelection.host {
		display: none;
	}
}

@media screen and (max-width: 600px) {
	.TournamentSelection {
		border-radius: 0;
		border: none;
	}

	.tournament_title {
		font-size: 2rem !important;
	}
}

.TournamentSelection__filter__container {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 20px 0px 25px;
}

.TournamentSelection_dropbtn {
	color: white;
	padding: 0px 10px;
	font-size: 16px;
	border: none;
	cursor: pointer;
	width: 100%;
	display: flex;
	height: 40px;
	align-items: center;
	border-radius: 10px;
	background: var(--cards-effect-background_secondary);
	-webkit-backdrop-filter: var(--cards-effect-blur);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
	cursor: pointer;
}

.TournamentSelection_dropbtn:hover,
.TournamentSelection_dropbtn:focus {
	opacity: 0.8;
}

.TournamentSelection_dropdown {
	position: relative;
	display: inline-block;
	height: 100%;
	width: 160px;
}

.TournamentSelection_dropdown_content {
	display: none;
	position: absolute;
	top: 45px;
	background-color: #f1f1f1;
	overflow: auto;
	width: 100%;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 1;
	border-radius: 8px;
	background: var(--cards-effect-background_secondary);
	-webkit-backdrop-filter: var(--cards-effect-blur);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
	cursor: pointer;
	padding: 2.5px;
	gap: 2.5px;
}

.TournamentSelection_dropdown_content .btn {
	color: #fff;
	height: 45px;
	width: 100%;
	text-decoration: none;
	padding: 6px;
	display: flex;
	align-items: center;

	padding: 5px;
}

.btn p {
	font-size: 0.9rem;
	/* width: 100%; */
}

.show {
	display: flex;
	flex-direction: column;
}

.line {
	height: 60%;
	width: 1px;
	background-color: #918d8d9e;
	border-radius: 100px;
}

.line_image_container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	margin-left: auto;
	gap: 0.8rem;
}

.arrow {
	width: 20px;
	transition: 0.2s ease-in-out all;
}

.btn {
	display: flex;
	align-items: center;
	text-align: center;
	transition: 0.2s ease-in-out all;
	border-radius: 8px;
}

.btn:hover {
	background: var(--cards-effect-background_secondary);
	cursor: pointer;
	border-radius: 8px;
	transform: scale(1.01);
	transition: 0.2s ease-in-out all;
}

.currentBtn {
	background: var(--cards-effect-background_secondary);
	-webkit-backdrop-filter: var(--cards-effect-blur);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
	cursor: pointer;
	border-radius: 8px;
	transition: 0.2s ease-in-out all;
}

.rotate {
	transform: rotate(180deg);
	transition: 0.2s;
}
