.lobby_card {
	width: 100%;
	background: var(--cards-effect-background-secondary);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
	border-radius: 13px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
}

.lobby_card:last-child {
	margin-bottom: 0;
}

.lobbyLeftSide {
	display: flex;
	width: 40%;
	align-items: center;
	gap: 10px;
}

.lobbyRightSide h1,
.lobbyLeftSide h1 {
	font-size: 1rem;
	color: var(--text-primary);
}


.waiting_button {
	border-radius: 50%;
	padding: 5px;
	color: var(--text-primary);
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	background: none;
	border: none;
}

.join_button {
	border-radius: 40px;
    padding: 0px 15px;
    color: var(--text-primary);
    display: flex;
    align-items: center;
	justify-content: center;
	gap: 10px;
    cursor: pointer;
    border: none;
    width: 100%;
    height: 100%;
}

.join_button img {
	width: 15px;
}

.join_button p {
	font-size: 0.9rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.player_image {
	width: 37px;
	height: 37px;
	border-radius: 50%;
    object-fit: cover;
}

.lobbyRightSide {
	width: 40%;
	display: flex;
	align-items: center;
	gap: 10px;
	justify-content: flex-end;
}

.middleContainer {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 8px;
}
.lobby_card_middle__vs {
	font-size: 1.5rem;
	font-family: 'Snes_Regular';
	color: var(--text-primary);
}

.lobby_card_middle__text {
	min-width: 100px;
	color: var(--text-primary);
	font-size: 0.8rem;
	border: 1px solid #383838;
	background: linear-gradient(
		90deg,
		rgba(56, 56, 56, 0.5) 0%,
		rgba(44, 44, 44, 0.5) 100%
	);
	padding: 5px 10px 2px 10px;
	border-radius: 20px 20px 0px 0px;
	width: 100%;
	text-align: center;
}

.remaining_time {
	font-size: 0.6rem;
}

.shaking {
	background: linear-gradient(
		90deg,
		rgb(26, 115, 40) 0%,
		rgba(8, 116, 49, 0.5) 80%
	);
	background-size: 200% 200%;
	animation: gradient 2s ease infinite;
	border: var(--cards-effect-border);
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}