.remember_device_container {
    display: flex;
    align-items: center;
    margin-top: 10px;
    gap: 10px;
}

.remember_device_container h1 {
    color: white;
}

.auth_form_content {
    display: flex;
	flex-direction: column;
	gap: 20px;
}