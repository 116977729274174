.security_root_container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.passwords_container {
	display: flex;
	justify-content: space-between;
	width: 100%;
	gap: 1rem;
}

.hideunhidepass_ic {
	cursor: pointer;
	transition: all 0.2s ease-in-out;
}

.hideunhidepass_ic:hover {
	transform: scale(1.05);
}

.form_holder {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	position: relative;
}

.intra_no_pass_view {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	gap: 15px;
	color: white;
	background-color: #1f1e21c5;
	position: absolute;
	border-radius: 10px;
}

.intra_no_pass_view img {
	width: 100px;
	height: 80px;
}

.intra_no_pass_view h1 {
	font-size: 1rem;
	max-width: 50%;
	text-align: center;
}