.profile_root_container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding-bottom: 30px;
}

.first_last_name_container {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 20px;
	gap: 1rem;
}

.danger_zone {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-top: 3rem;
	color: white;
}

.danger_zone_title {
	font-size: 1rem;
	font-weight: normal;
	padding: 0 6px;
}

.danger_zone_content {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 15px;
	border-radius: 10px;
	gap: 10px;
}

.danger_zone_item {
	display: flex;
	width: 100%;
	background: var(--cards-effect-background_secondary);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
	border-radius: 18px;
	padding: 14px 12px;
	flex-direction: column;
	gap: 8px;
	cursor: pointer;
	transition: all ease-in-out 0.2s;
}

.danger_zone_item h1 {
	font-size: 16px;
	color: rgb(231, 231, 231);
	transition: all ease-in-out 0.2s;
}

.danger_zone_item p {
	font-size: 14px;
	color: #a3a3a3;
	transition: all ease-in-out 0.2s;
}

.danger_zone_item:hover {
	border: 1px solid rgba(126, 39, 49, 1);
}

.danger_zone_item:hover p, .danger_zone_item:hover h1 {
	color: rgb(190, 91, 102);
}

/* componenets */

.remember_device_container {
    display: flex;
    align-items: center;
    margin-top: 20px;
    gap: 10px;
	width: 100%;
	padding: 14px 10px;
	background: var(--cards-effect-background_secondary);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
	border-radius: 18px;
}

.remember_device_container h1 {
    color: white;
}

.switch {
	--secondary-container: #3b36c5;
	--primary: #a8a6e9;
	font-size: 17px;
	position: relative;
	display: inline-block;
	width: 3.7em;
	height: 1.8em;
}

.switch input {
	display: none;
	opacity: 0;
	width: 0;
	height: 0;
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #313033;
	transition: 0.2s;
	border-radius: 30px;
}

.slider:before {
	position: absolute;
	content: '';
	height: 1.4em;
	width: 1.4em;
	border-radius: 20px;
	left: 0.2em;
	bottom: 0.2em;
	background-color: #aeaaae;
	transition: 0.4s;
}

input:checked + .slider::before {
	background-color: var(--primary);
}

input:checked + .slider {
	background-color: var(--secondary-container);
}

input:focus + .slider {
	box-shadow: 0 0 1px var(--secondary-container);
}

input:checked + .slider:before {
	transform: translateX(1.9em);
}