.logs_container {
	width: 100%;
	height: 80px;
	display: flex;
	align-items: center;
	animation: slide-in 0.1s;
}

@keyframes slide-in {
	from {
		transform: translateX(-100%);
	}
	to {
		transform: translateX(0);
	}
}

.logs_card_container {
	width: 100%;
	height: 80%;
	padding: 0 7px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.mapPreview_container {
	width: 100%;
	height: 100%;
}

.line_break {
	width: 1px;
	height: 100%;
	margin-bottom: 20px;
	background: rgba(145, 141, 141, 0.6);
}

.logs_card {
	width: 100%;
	height: 100%;
	background: var(--cards-effect-background-secondary);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
	border-radius: 13px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 10px;
}
.logs_card_loser {
	background-color: rgb(249, 90, 90);
	width: 5px;
	height: 50%;
	border-radius: 0 20px 20px 0;
}
.logs_card_winner {
	background-color: rgb(67, 192, 125);
	width: 5px;
	height: 50%;
	border-radius: 20px 0 0 20px;
}

.logsLeftSide {
	display: flex;
	width: 40%;
	align-items: center;
	gap: 10px;
}

.logsRightSide h1,
.logsLeftSide h1 {
	font-size: 1rem;
	color: var(--text-primary);
}

.logsLeftSide img {
	width: 37px;
	height: 37px;
	border-radius: 50%;
    object-fit: cover;
}

.logsRightSide {
	width: 40%;
	display: flex;
	align-items: center;
	gap: 10px;
	justify-content: flex-end;
}

.logsRightSide img {
	width: 37px;
	border-radius: 50%;
}

.middleContainer {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 8px;
}
.logs_card_middle__vs {
	margin-top: auto;
	font-size: 1rem;
	font-weight: bolder;
	font-family: 'Snes_Regular';
	color: var(--text-primary);
}

.logs_card_middle__text {
	min-width: 90px;
	color: var(--text-primary);
	font-size: 0.7rem;
	border: 1px solid #383838;
	background: linear-gradient(
		90deg,
		rgba(56, 56, 56, 0.5) 0%,
		rgba(44, 44, 44, 0.5) 100%
	);
	padding: 5px 10px 2px 10px;
	border-radius: 20px 20px 0px 0px;
	width: 100%;
	text-align: center;
}
