.user_basic_info_container {
	width: 100%;
	display: flex;
	padding: 3rem 0 2rem 0;
	flex-direction: column;
}

.user_basic_info_container h1 {
	max-width: 100%;
}

.user_basic_info_container h1 span {
	color: var(--text-primary);
	margin: 0.5rem 0.5rem 0.5rem 0.5rem;
	font-size: 3rem;
	font-weight: bolder;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
	letter-spacing: 0.03em;
	background: linear-gradient(
		0deg,
		rgba(126, 39, 49, 0) 0%,
		rgba(126, 39, 49, 0) 10%,
		rgba(126, 39, 49, 1) 10%,
		rgba(126, 39, 49, 1) 50%,
		rgba(126, 39, 49, 0) 50%
	);
	padding: 0 0.2em;
	box-decoration-break: clone;
	-webkit-box-decoration-break: clone;
	text-align: start;
}

.user_basic_info_container img {
	width: 150px;
	height: 150px;
	border-radius: 60%;
	object-fit: cover;
}

@media screen and (max-width: 600px) {
	.user_basic_info_container {
		padding: 1.5rem 0 2rem 0;
	}
	.user_basic_info_container h1 span {
		margin: 0.5rem 0.5rem 0.5rem 0.3rem;
		font-size: 2.2rem;
	}
	.user_basic_info_container img {
		display: none;
	}
}

.info_container {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.position {
	position: relative;
	top: -32px;
	right: 16px;
}
