.TournamentRoom {
	width: 100%;
	height: 100%;
	border-radius: 30px;
	display: flex;
	align-items: center;
	flex-direction: column;
	overflow-y: auto;
	padding: 20px;
	gap: 20px;
	max-width: 1400px;
	animation: var(--enteranceAnim);
	background: var(--cards-effect-background_secondary);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
}

.join_start_tournament {
	width: 100%;
	max-width: 200px;
	height: 100%;
	max-height: 100px;
	background: #488133;
	margin: 0 0 0 auto;
	border-radius: 40px;
	outline: none;
	border: none;
	color: var(--text-primary);
	font-size: 1.2rem;
	cursor: pointer;
}

::-webkit-scrollbar {
	display: none;
}

.back {
	display: flex;
	align-items: center;
	justify-content: center;
	background: transparent;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	transition: scale 1s;
}

.waitingButton {
	border: none;
	background: #4441ba !important;
	padding: 10px 20px 10px 20px;
	display: flex;
	align-items: center;

	color: var(--text-primary);
	font-size: 1.2rem;
	cursor: wait;
	display: flex;
	justify-content: space-around;
}

.exitTournament {
	border: none;
	background: #4441ba !important;
	padding: 10px;
	text-overflow: ellipsis;
	white-space: nowrap;
	align-items: center;
	color: var(--text-primary);
	font-size: 1rem;
	cursor: pointer;
	display: flex;
	justify-content: space-around;
}

.back:hover {
	scale: 1.1;
	background: rgba(43, 43, 43, 0.598);
	cursor: pointer;
}

.title {
	display: flex;
	width: 100%;
	gap: 20px;
	align-items: center;
}

.tournament_title_holder {
	width: 100%;
	display: flex;
	align-items: center;
	gap: 10px;
}

.tournament_title_holder h1 {
	color: var(--text-primary);
	font-size: 2rem;
	transform: translateY(-3px);
	transition: all 0.5s;
}

.knockout_container {
	width: 100%;
	height: 400px;
	min-height: 350px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 1rem;
	padding: 1rem;
	overflow-y: auto;
	border-radius: 30px;
}

.knockout_container::-webkit-scrollbar {
	display: none;
}

@media (max-width: 1790px) {
	.knockout_container {
		justify-content: normal !important;
	}
}

.knockout__line_center {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 150%;
	height: 1px;
	background-color: #555453;
	z-index: 0;
	transform: translate(-50%, -50%);
}

.knockout_container .leftSide {
	flex: 2;
	height: 100%;
}

.knockout_container .rightSide {
	flex: 2;
	height: 100%;
}
.knockout_container .center {
	flex: 1;
	height: 100%;
}

.leftSide,
.rightSide {
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 1rem;
	position: relative;
}

.leftSide > *,
.rightSide > * {
	height: 20%;
	width: 50%;
	border-radius: 40px;
	z-index: 2;
	display: flex;
	align-items: center;
	padding-left: 5px;
	gap: 10px;
}

.rightSide {
	display: flex;
	flex-direction: column;
	margin-left: auto;
	padding: 5px;
}

.leftSide > * img,
.rightSide > * img {
	object-fit: cover;
	border-radius: 40px;
	height: 42px;
	width: 42px;
}

.rightSide .knockout__lines {
	border-right: none;
	border-left: 1px solid #555453;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	right: 25%;
	border-radius: 20px 0px 0px 20px;
}

.leftSide .knockout__lines {
	border-left: none !important;
	border-left: 1px solid #555453;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	left: 25%;
	border-radius: 0px 20px 20px 0px;
}

.rightSide > *:nth-child(2) {
	background-color: #383838;
	border: 1px solid #555453;
	margin-right: auto;
}

.leftSide > *:nth-child(2) {
	background-color: #383838;
	border: 1px solid #555453;
	margin-left: auto;
}
.leftSide > *:nth-child(3),
.rightSide > *:nth-child(3) {
	background-color: #383838;
	border: 1px solid #555453;
}
.leftSide > *:nth-child(1),
.rightSide > *:nth-child(1) {
	background-color: #383838;
	border: 1px solid #555453;
}

.rightSide > *:nth-child(1),
.rightSide > *:nth-child(3) {
	margin-left: auto;
}

.player_name h1 {
	color: var(--text-primary);
	font-weight: normal;
	font-size: 0.9rem;
}

.knockout__lines {
	position: absolute;
	left: 25%;
	width: 50%;
	height: 45%;
	z-index: 1;
	border: 1px solid #555453;
	border-left: none;
}

.center {
	color: var(--text-primary);
	padding: 0 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	position: relative;
	z-index: 2;
}

.center_container__overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 50px;
	z-index: 1;
}

.knockout_title {
	color: var(--text-primary);
	font-size: 1.5rem;
	font-weight: normal;
}

.winner_title {
	color: var(--text-primary);
	font-size: 2.2rem;
	font-family: 'Snes_Regular';
	font-weight: normal;
	letter-spacing: 2px;
}

.center_container {
	display: flex;
	height: 63%;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 1px solid #3e3e3e;
	border-radius: 40px;
	max-width: 160px;
	position: relative;
	z-index: 1000;
	overflow: hidden;
	background: linear-gradient(180deg, rgba(0, 0, 0, 87%) 10%, rgba(0, 0, 0, 1) 100%);
}

.center_container h1 {
	color: var(--text-primary);
	margin-top: 20px;
	text-align: center;
	max-width: 140px;
	z-index: 1;
}

.center_trophy {
	position: absolute;
	height: 115%;
	justify-content: center;
	align-items: center;
	opacity: 0.4;
	transform: translateX(25%);
}

.center_info_container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}

.center_info_container img {
	z-index: 1000;
	width: 50%;
	object-fit: cover;
	border-radius: 50%;
	box-shadow: 0 0 45px 2px rgba(255, 215, 0, 0.75);
}

.leftSide {
	min-width: 400px;
	z-index: 1000;
	max-width: 400px;
}

.rightSide {
	min-width: 400px;
	max-width: 400px;
}

.center {
	min-width: 230px;
	max-width: 450px;
}

.matches {
	width: 100%;
	height: 100%;
	border-radius: 40px;
	display: flex;
	flex-direction: column;
	min-height: 620px;
}

.title_container {
	display: flex;
	width: 100%;
	height: 100px;
	justify-content: center;
}

.match_details {
	color: var(--text-primary);
	width: 15%;
	font-size: 1.2rem;
	padding: 20px 0px;
	text-align: center;
	min-width: 140px;
}

.leftLine {
	width: 50%;
	display: flex;
	flex-direction: column;
	margin-top: 30px;
	align-items: center;
}
.rightLine {
	width: 50%;
	display: flex;
	flex-direction: column;
	margin-top: 30px;
	align-items: center;
}

.logs,
.lobby {
	width: 120px;
	height: 40px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	align-self: center;
	color: var(--text-primary);
	border-radius: 30px;
	font-size: 1.2rem;
	padding: 20px 0;
	position: relative;
}

.mapPreview {
	padding: 5px 10px;
	background-color: var(--text-primary);
	color: #2b2a29;
	border-radius: 30px;
	font-size: 1.2rem;
}

.content_container {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	padding-bottom: 20px;
}

.logs_container {
	width: 50%;
	height: 100%;
	max-height: 500px;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
}

.logs_container::-webkit-scrollbar {
	display: none;
}

.lobby_container {
	width: 50%;
	height: 100%;
	max-height: 500px;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 20px;
	gap: 1.5rem;
}

.line_break {
	width: 1px;
	height: 100%;
	margin-bottom: 20px;
	background: #918d8d9e;
}

.navigation {
	display: flex;
	align-items: center;
	justify-content: space-around;
	gap: 20px;
}

.winnerSkeleton {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;
	padding: 20px;
}

.loader {
	border: 4px solid rgba(0, 0, 0, 0.1);
	border-left-color: transparent;
	border-radius: 50%;
}

.loader {
	border: 4px solid rgba(0, 0, 0, 0.1);
	border-left-color: transparent;
	width: 23px;
	height: 23px;
	animation: spin 1s linear infinite;
}

.input_box {
	width: 100%;
	height: 100%;
	border-radius: 20px;
	display: flex;
	align-items: center;
	padding: 0 5px 0 10px;
	background: var(--cards-effect-background_secondary);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
	animation: slide-in 0.3s;
}

@keyframes slide-in {
	from {
		transform: translateX(200%);
	}
	to {
		transform: translateX(0);
	}
}

.input_box img {
	width: 26px;
}

.input_box input {
	width: 100%;
	height: 100%;
	background: transparent;
	border: none;
	outline: none;
	color: var(--text-primary);
	font-size: 1.2rem;
	padding-left: 15px;
}

.input_button {
	height: 40px;
	width: 140px;
	background: rgb(18, 145, 31);
	border: none;
	outline: none;
	border-radius: 15px;
	color: #fff;
	cursor: pointer;
}

.input_box input::placeholder {
	font-size: 1rem;
	opacity: 0.7;
}

.icon_resize {
	width: 1rem;
}

.admin_buttons {
	display: flex;
	gap: 1rem;
	width: 100%;
	display: flex;
	justify-content: end;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@media (max-width: 600px) {
	.TournamentRoom {
		padding: 5px;
		border-radius: 0;
		border: none;
	}

	.admin_buttons {
		margin-left: 0;
	}

	.title {
		flex-direction: column;
	}

	.buttons {
		width: 100% !important;
	}
}

@media (max-width: 1000px) {
	.line_break {
		display: none;
	}

	.TournamentRoom {
		padding: 10px;
	}

	.matches {
		border-radius: 20px;
		min-height: auto;
		height: unset;
	}

	.title {
		display: flex;
		width: 100%;
		gap: 10px;
		align-items: center;
		padding: 0;
	}

	.tournament_title_holder h1 {
		font-size: 1.2rem;
		transition: all 0.5s;
		transform: translateY(-2px);
	}

	.tournament_title_holder .back img {
		width: 20px;
	}

	.content_container {
		flex-direction: column;
		width: 100%;
	}

	.logs_container {
		width: 100%;
		overflow: unset;
	}

	.lobby_container {
		width: 100%;
		max-height: none;
		overflow-y: unset;
	}
}
