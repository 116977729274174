.ListItem {
	padding: 10px;
	border: 1px solid #383838;
	height: 70px;
	width: 100%;
	border-radius: 80px;
	display: flex;
	align-items: center;
	cursor: pointer;
	gap: 10px;
}

.firstPlace {
	background: linear-gradient(
		90deg,
		rgba(255, 217, 0, 0.5) 0%,
		rgba(124, 100, 55, 0.5) 80%
	);
	background-size: 200% 200%;
	animation: gradient 2s ease infinite;
}

.secondPlace {
	background: linear-gradient(
		90deg,
		rgba(134, 134, 134, 0.5) 0%,
		rgba(44, 44, 44, 0.5) 100%
	);
}

.thirdPlace {
	background: linear-gradient(
		90deg,
		rgba(112, 61, 24, 0.5) 0%,
		rgba(44, 44, 44, 0.5) 100%
	);
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.ListItem img {
	width: 50px;
	height: 50px;
	object-fit: cover;
	border-radius: 50%;
}

.PlayerInfo {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 70px;
	margin-inline-end: 10px;
	gap: 5px;
}

.PlayerInfo p {
	color: var(--text-primary);
	margin: 0;
	font-size: 1.2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 235px;
}

.PlayerInfo p:last-child {
	color: #d2d2d2;
	font-size: 0.8rem;
}

.medal {
	margin-inline-start: auto;
	width: 30px !important;
	height: 30px !important;
	object-fit: fill !important;
	margin-inline-end: 10px;
}

.RankingNumber {
	margin-inline-start: auto;
	width: 30px;
	height: 30px;
	margin-inline-end: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.RankingNumber h1 {
	color: var(--text-primary);
	font-size: 2rem;
	text-align: center;
	font-family: 'Snes_Regular';
	font-weight: normal;
	transform: translateY(3px);
}

.rest li {
	background: linear-gradient(90deg, rgba(56, 56, 56, 1) 0%, rgba(44, 44, 44, 1) 100%);
}
