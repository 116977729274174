.dashboard_container_root,
.dashboard_container_root_hidden {
	grid-area: main;
	margin: 1rem 1rem 0rem 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	height: calc(100vh - var(--navbar-height) - 4rem);
	gap: 1rem;
}

.dashboard_container_root::-webkit-scrollbar {
	display: none;
}

/* start friends & history container stuff */
.user_fh_container,
.user_fh_container_hidden {
	overflow: scroll;
	width: 100%;
	height: 100%;
	max-width: 420px;
	min-width: 420px;
	border-radius: 30px;
	background: var(--cards-effect-background);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
	padding: 10px 0;
	animation: var(--enteranceAnim);
}

.friends_container {
	width: 100%;
	padding: 0.5rem 0.5rem 0rem 0.5rem;
}

.friends_container > h1 {
	padding: 0 0.4rem 0.2rem 0.4rem;
}

.friends_container::-webkit-scrollbar {
	display: none;
}

.fh_title {
	font-size: 1.5rem;
	font-weight: 600;
	color: var(--text-primary);
	padding: 0.5rem 1rem 0.7rem 1rem;
}
/* end friends & history container stuff */

/* start main dashboard */

.user_stats_card_root,
.user_stats_card_root_hidden {
	width: 100%;
	height: 100%;
	max-width: 1400px;
	border-radius: 30px;
	background: var(--cards-effect-background_secondary);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
	position: relative;
	overflow: scroll;
	animation: var(--enteranceAnim);
}

.announcement_banner {
	width: 100%;
	height: 50px;
	position: absolute;
	z-index: 1;
	padding: 0.5rem 1rem;
	background: linear-gradient(180deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
	overflow: hidden;
}

.announcement_banner h1 {
	animation: marquee 30s linear infinite;
	color: #fff;
	display: inline-block;
	font-weight: 400;
	white-space: nowrap;
	will-change: transform;
	padding: 5px;
	box-shadow: var(--cards-effect-shadow);
}

@keyframes marquee {
	0% {
		transform: translateX(100%);
	}
	100% {
		transform: translateX(-100%);
	}
}

.user_stats_container {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
}

.user_background_cover {
	width: 100%;
	height: 40%;
	object-fit: cover;
	mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
}

.user_info_container {
	width: 100%;
	position: absolute;
	top: 0;
	display: flex;
	flex-direction: column;
	padding: 0 2rem;
}

.friends_btn {
	background: var(--cards-effect-background);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
	color: white;
	padding: 1rem;
	margin: 15px 0;
	border-radius: 40px;
	display: none;
}

.user_basic_info_container {
	width: 100%;
	display: flex;
	padding: 3rem 0 2rem 0;
	justify-content: space-between;
}

.user_basic_info_container h1 {
	max-width: 100%;
}

.user_basic_info_container h1 span {
	color: var(--text-primary);
	margin: 0.5rem 0.5rem 0.5rem 0.5rem;
	font-size: 3rem;
	font-weight: bolder;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
	letter-spacing: 0.03em;
	background: linear-gradient(
		0deg,
		rgba(126, 39, 49, 0) 0%,
		rgba(126, 39, 49, 0) 10%,
		rgba(126, 39, 49, 1) 10%,
		rgba(126, 39, 49, 1) 50%,
		rgba(126, 39, 49, 0) 50%
	);
	padding: 0 0.2em;
	box-decoration-break: clone;
	-webkit-box-decoration-break: clone;
	text-align: start;
}

.user_basic_info_container img {
	width: 150px;
	height: 150px;
	border-radius: 50%;
	object-fit: cover;
}

.user_level_container {
	width: 100%;
	height: 30px;
	display: flex;
	padding: 0 0.4rem;
	display: flex;
	flex-direction: column;
	margin-bottom: 1rem;
}

.container {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 3rem;
}

.buttons_container {
	width: 100%;
	height: 100%;
	display: flex;
	max-width: 316px;
	gap: 1rem;
	align-items: flex-end;
}

.friend_request_btn {
	max-width: 150px;
	width: 100%;
	height: 40px;
	display: flex;
	justify-content: space-around;
	align-items: center;
	background-color: #4441ba;
	border-radius: 15px;
	box-shadow: 0px 0px 20px 0px #4441ba;
	cursor: pointer;
	transition: 0.3s;
	outline: none;
	border: none;
	color: var(--text-primary);
	padding: 0 1rem;
}

.message_btn {
	padding: 0 1rem;
	max-width: 150px;
	width: 100%;
	height: 40px;
	display: flex;
	justify-content: space-around;
	align-items: center;
	color: var(--text-primary);
	border-radius: 15px;
	cursor: pointer;
}

.button_icon {
	width: 20px;
	height: 20px;
}

.user_level_container h1 {
	color: var(--text-primary);
	font-size: 1rem;
}
.user_level_container p {
	color: rgb(226, 226, 226);
	font-size: 0.8rem;
}

.user_level_progress {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background: black;
	margin: 10px 0 0 0;
	border-radius: 10px;
}

.user_level_progress_bar {
	width: 50%;
	height: 10px;
	border-radius: 10px;
	background: linear-gradient(90deg, rgba(126, 39, 49, 1) 90%, rgba(0, 0, 0, 0) 100%);
}

.user_stats_grid {
	width: 100%;
	height: 100%;
	display: grid;
	margin-top: 2rem;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(3, 0fr);
	grid-column-gap: 17px;
	grid-row-gap: 7px;
	grid-template-areas:
		'perf_title prog_title prog_title'
		'user_general_stats user_bp_stats user_bp_stats'
		'footer footer footer';
	color: var(--text-primary);
}

.user_performance_title {
	grid-area: perf_title;
	padding: 0 0 0.4rem 0.4rem;
}

.user_progress_title {
	grid-area: prog_title;
	padding: 0 0 0.4rem 0.4rem;
}
.footer_2 {
	grid-area: footer;
  height: 60px;
  margin: 20px 0;
  display: flex;
  align-items: center;
  background: var(--cards-effect-background);
  -webkit-backdrop-filter: var(--cards-effect-blur);
  backdrop-filter: var(--cards-effect-blur);
  box-shadow: var(--cards-effect-shadow);
  border: var(--cards-effect-border);
  border-radius: 20px;
  overflow-x: scroll;
  padding: 0px 16px;
  gap: 0.5rem;
  width: 100%;
}
.footer {
	grid-area: footer;
	height: 60px;
	margin: 20px 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background: var(--cards-effect-background);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
	border-radius: 20px;
	overflow-x: scroll;
	padding: 0px 16px;
	gap: 1rem;
}

/* end main dashboard */

/* start responsiveness */
@media screen and (max-width: 1360px) {
	.user_stats_grid {
		grid-column-gap: 0px;
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: repeat(5, 0fr);
		grid-template-areas:
			'perf_title perf_title perf_title'
			'user_general_stats user_general_stats user_general_stats'
			'prog_title prog_title prog_title'
			'user_bp_stats user_bp_stats user_bp_stats'
			'footer footer footer';
	}

	.user_progress_title {
		padding: 1rem 0 0.4rem 0.4rem;
	}
}

@media screen and (max-width: 1100px) {
	.user_stats_grid {
		margin-top: 0rem;
	}
	.user_stats_card_root_hidden {
		display: none;
	}

	.user_fh_container_hidden {
		display: none;
		max-width: 100%;
		min-width: auto;
	}

	.user_fh_container {
		max-width: 100%;
		min-width: auto;
	}

	.user_info_container {
		padding: 0 1rem;
	}

	.friends_btn {
		display: block;
	}
}

@media screen and (max-width: 600px) {
	.dashboard_container_root {
		margin: 0;
		height: calc(100svh - var(--navbar-height-mobile) * 2);
	}

	.user_stats_card_root {
		height: 100%;
		max-width: 100%;
		border-radius: 0;
		background: var(--cards-effect-background_secondary);
		backdrop-filter: var(--cards-effect-blur);
		box-shadow: 0;
		border: 0;
	}

	.user_basic_info_container {
		padding: 1.5rem 0 2rem 0;
	}

	.user_basic_info_container h1 span {
		margin: 0.5rem 0.5rem 0.5rem 0.3rem;
		font-size: 2.2rem;
	}

	.user_basic_info_container img {
		display: none;
	}

	.user_fh_container {
		border: none;
		border-radius: 0;
	}
}

.arrowHolder {
	height: 30px;
	width: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	background: transparent;
	border: var(--cards-effect-border);
	user-select: none;
}
.Arrow {
	width: 20px;
	user-select: none;
	transition: all 0.5s ease;
	font-weight: lighter;
}

.friend_request_see_more {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.5rem;
	gap: 0.5rem;
	border-radius: 2rem;
	margin-bottom: 10px;
	cursor: pointer;
}

.historyContainer {
	width: 100%;
	height: fit-content;
	border-radius: 20px;
	padding: 0.5rem 0 0rem 0;
}
