.Ranking {
	width: 420px;
	min-width: 420px;
	height: 100%;
	border-radius: 30px;
	padding: 20px 20px 100px 20px;
	position: relative;
	animation: var(--enteranceAnim);
}

.Ranking {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.RankingTitle {
	color: var(--text-primary);
	width: 100%;
}

.RankingTitle h1 {
	font-size: 2rem;
}

.RankingGamesTabLayout {
	width: 100%;
	border-radius: 30px;
	display: flex;
	justify-content: space-evenly;
	padding: 0px 5px;
	margin: 20px 0px 10px 0px;
	align-items: center;
	height: 50px;
	min-height: 50px;
	position: relative;
}

.separator {
	width: 1px;
	height: 60%;
	margin: 0 10px;
	background: var(--separator-color);
	align-self: center;
}

.PingPongTab,
.TicTacToeTab {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0px 5px;
	transition: 0.3s;
	height: 80%;
	width: 50%;
}

.PingPongTab:hover,
.TicTacToeTab:hover {
	background: #0000001d;
	border-radius: 25px;
	cursor: pointer;
	transition: 0.3s;
}

.PingPongTab p,
.TicTacToeTab p {
	color: var(--text-primary);
}

.TabIndicator {
	position: absolute;
	bottom: 0;
	right: 50px;
	width: 80px;
	height: 4px !important;
	color: var(--text-primary);
	border-radius: 5px 5px 0px 0px;
	transition: 0.3s;
}

.BestPlayers {
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 100%;
}

.ListItem {
	padding: 10px 5px;
	border: 1px solid #383838;
	height: 70px;
	width: 100%;
	border-radius: 80px;
	display: flex;
	align-items: center;
}

.ListItem img {
	width: 50px;
	height: 50px;
	border-radius: 50%;
}

.PlayerInfo {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 70px;
	margin-left: 10px;
	gap: 5px;
}

.PlayerInfo p {
	color: var(--text-primary);
	margin: 0;
	font-size: 1.2rem;
}

.PlayerInfo p:last-child {
	color: #d2d2d2;
	font-size: 0.8rem;
}

.RankingNumber {
	margin-left: auto;
	width: 30px;
	height: 30px;
	margin-right: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.RankingNumber h1 {
	color: var(--text-primary);
	font-size: 2rem;
	text-align: center;
	font-family: 'Snes_Regular';
	font-weight: normal;
	transform: translateY(3px);
}

.RankingList {
	width: 100%;
	height: calc(100% + 50px);
	overflow: auto;
}
.RankingList::-webkit-scrollbar {
	display: none;
}

.BestPlayers p {
	color: #746f88;
	font-size: 1rem;
	margin: 20px 0px;
}

.rest li {
	background: linear-gradient(90deg, rgba(56, 56, 56, 1) 0%, rgba(44, 44, 44, 1) 100%);
}

.MyRanking {
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 175px;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 50%, rgb(0 0 0 / 0%) 80%);

	border-radius: 0px 0px 30px 30px;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	padding: 20px;
}

.MyRanking li {
	background: none;
	border: none;
}

@media (max-width: 1700px) {
	.Ranking {
		display: none;
	}
}
