.form_container {
	display: flex;
	align-items: flex-end;
	flex-direction: column;
	width: 100%;
}

.info_item {
	display: flex;
	height: 60px;
	width: 100%;
	align-items: center;
	background: var(--cards-effect-background_secondary);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
	border-radius: 18px;
	padding: 0 14px;
	gap: 10px;
}

.info_item img {
	height: 25px;
	width: 25px;
}

.info_item input {
	width: 100%;
	background: none;
	border: none;
	outline: none;
	color: var(--text-primary);
	font-size: 16px;
}

.input_layout_container {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.input_layout_container label {
	padding: 10px;
	font-size: 16px;
	color: rgba(163, 163, 163, 1);
}

.save_changes_button {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px 20px;
	border-radius: 12px;
	gap: 10px;
	cursor: pointer;
	border: none;
	outline: none;
	transition: all ease-in-out 0.2s;
	margin-top: 20px;
}

.save_changes_button:hover {
	background: rgb(221, 221, 221);
	transform: scale(1.02);
}

.save_changes_button h1 {
	font-size: 16px;
	font-weight: normal;
}