.selector {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 50px;
  position: relative;
}

.buttons {
  width: 100%;
  height: 100%;
  max-height: 100px;
  min-height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 40px;
  padding: 4px;
}

.tabLayout {
  position: absolute;
  width: calc((100% - 4px) / 8);
  height: 3px;
  border-radius: 40px 40px 0px 0px;
  background-color: var(--text-primary);
  bottom: 0;
  left: calc(35.5% - ((100% - 4px) / 6));
  transition: 0.3s;
}

.rightSide {
  width: 49%;
  height: 100%;
  border-radius: 40px;
  cursor: pointer;
}

.rightSide:hover ,
.leftSide:hover {
  background: #0000001d;
  transition: 0.3s;
}

.leftSide {
  width: 49%;
  height: 100%;
  border-radius: 40px;
  cursor: pointer;
}

.separator {
  width: 2%;
  height: 50%;
  max-width: 1px;
  background: var(--separator-color);
}

.Text {
  color: var(--text-primary);
  font-size: 1rem;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Text p {
  font-weight: lighter;
}
