@import '../../../../App.css';

.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100%;
	padding: 30px 40px 40px 40px;
	gap: 40px;
	border-radius: 25px;
	overflow: auto;
	background: var(--cards-effect-background);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
}

.customizeTitle {
	color: var(--text-primary);
	font-size: 3rem;
	font-weight: normal;
	font-family: 'Snes_Regular';
}

.buttonsContainer {
	display: flex;
	align-items: center;
	border: var(--cards-effect-border-secondary);
	border-radius: 50px;
	padding: 5px;
	margin-top: 1rem;
}

.PingPongButton,
.TicTacToeButton {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	color: var(--text-primary);
	border-radius: 60px;
	width: 100%;
	padding: 10px 3rem;
	height: 81px;
	cursor: pointer;
	gap: 14px;
}

.PingPongButton svg,
.TicTacToeButton svg {
	width: 30px;
	height: 30px;
}

.TicTacToeButton h1,
.PingPongButton h1 {
	white-space: nowrap;
	font-weight: normal;
}

.titleText {
	color: var(--text-primary);
	font-weight: normal;
	font-family: 'Snes_Regular';
	font-size: 2rem;
	width: 100%;
}

.friendAndPreview {
	display: flex;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	gap: 20px;
	align-items: center;
}

.friends {
	width: 50%;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.line {
	width: 1px;
	height: 70%;
	background: #444444;
}

.previewContainer {
	width: 50%;
	height: 100%;
	min-height: 440px;
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.preview {
	width: 100%;
	height: calc(100% - 2rem);
	border-radius: 35px;
	overflow: hidden;
	position: relative;
	composes: background_secondary from '../../../../App.css';
}

.gameBoyContainer {
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.gameBoyView {
	transform: rotate(28deg) scale(0.55) translateX(-332px) translateY(-186px);
}

.logoIcon {
	position: absolute;
	top: 20px;
	right: 20px;
	width: 50px;
	height: 50px;
	opacity: 0.5;
}

/* responsiveness */

@media (max-width: 1600px) {
	.gameBoyView {
		transform: rotate(25deg) scale(0.58) translateX(-362px) translateY(-286px);
	}
	.preview {
		border-radius: 30px;
	}
}

@media (max-width: 1220px) {
	.gameBoyView {
		transform: rotate(28deg) scale(0.65) translateX(-362px) translateY(-256px);
	}
}

@media (max-width: 900px) {
	.container {
		gap: 50px;
	}
	.friendAndPreview {
		flex-direction: column;
		gap: 50px;
	}
	.previewContainer {
		width: 100%;
		height: 440px;
	}
	.gameBoyView {
		transform: rotate(28deg) scale(0.7) translateX(-332px) translateY(-186px);
	}
	.line {
		display: none;
	}
	.friends {
		width: 100%;
	}
}

@media (max-width: 700px) {
	.container {
		padding: 40px 15px;
	}
	.customizeTitle {
		font-size: 3rem;
	}
	.titleText {
		font-size: 1.7rem;
	}
	.buttonsContainer {
		transform: scale(0.8);
		margin-top: 0px;
	}
	.selectorContainer {
		flex-direction: column;
		gap: 0.5rem;
		justify-content: center;
		align-items: center;
	}
	.container {
		border-radius: 15px;
	}
}

.title_offline_online {
	display: flex;
	width: 100%;
	/* height: 100%; */
	justify-content: space-between;
	align-items: center;
}

.online_switcher {
	display: flex;
	align-items: center;
	border: var(--cards-effect-border-secondary);
	width: 150px;
	height: 100%;
	border-radius: 50px;
	padding: 5px;
}

.onlineButton,
.offlineButton {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	color: var(--text-primary);
	border-radius: 60px;
	width: 100%;
	height: 100%;
	cursor: pointer;
	padding: 5px;
}

.onlineButton img,
.offlineButton img {
	width: 25px !important;
}

.offlineButton h1,
.onlineButton h1 {
	white-space: nowrap;
	font-weight: normal;
}

@media screen and (max-width: 1200px) {
	.title_offline_online {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}
}

.selectorContainer {
	width: 100%;
	display: flex;
	justify-content: space-between;
	gap: 1rem;
}

.maxWidthOnline {
	max-width: 200px;
}

.title_offline_online div {
	max-width: 200px;
}

.maxWidthGames {
	max-width: 500px;
}

.borderOnline {
	border: 1px solid #2c4b34;
	box-shadow: 0 0 5px rgb(63, 120, 90, 0.5);
	transition: all 0.5s;
}

.borderOffline {
	border: 1px solid #4f3b3b;
	box-shadow: 0 0 5px rgba(249, 90, 90, 0.5);
	transition: all 0.5s;
}
