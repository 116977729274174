.container {
	display: flex;
	height: 50px;
	min-width: 180px;
	align-items: center;
	padding: 5px 15px 5px 3px;
	border-radius: 25px;
	cursor: pointer;
	background: var(--cards-effect-background_secondary);
	-webkit-backdrop-filter: var(--cards-effect-blur);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
}

.user_data_view {
	display: flex;
	flex-direction: column;
	gap: 3px;
}

.user_data_view h1 {
	font-size: 14px;
	color: var(--text-color-primary);
}

.user_data_view p {
	color: var(--text-color-secondary);
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100px;
    overflow: hidden;
}

.picture {
	height: 43px;
	width: 43px;
	object-fit: cover;
	border-radius: 50%;
	margin-right: 10px;
}

.full_name {
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100px;
}