.tablayout_container {
	width: 100%;
	border-radius: 50px;
	background: var(--cards-effect-background_secondary);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
	position: relative;
}

.options_holder {
	display: flex;
	justify-content: space-between;
	padding: 0 0.4rem;
}

.option_container {
	display: flex;
	justify-content: center;
	width: 100%;
	align-items: center;
	border-radius: 50px;
	cursor: pointer;
	gap: 0.4rem;
	overflow: hidden;
	user-select: none;
	padding: 1rem 0;
}

.option_container h1 {
	font-size: 1rem;
	font-weight: normal;
	color: var(--text-primary);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: center;
}

.option_container img {
	width: 1.5rem;
	height: 1.5rem;
	padding: 0.2rem;
	object-fit: cover;
	color: white;
}

.tab_selector {
	width: 50%;
	height: 80%;
	border-radius: 50px;
	background: var(--cards-effect-background_tertiary);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-selected-border);
	backdrop-filter: var(--cards-effect-blur);
	position: absolute;
	top: 10%;
	margin: var(--selector-margin);
	z-index: -1;
	transition: all 0.2s ease-in-out;
	animation: to-left 0.6s cubic-bezier(0.23, 1, 0.32, 1) both;
	transform: translateX(0);
}

@keyframes to-left {
	0% {
		transform: var(--selector-active-location);
	}
	100% {
		transform: translateX(0);
	}
}

@keyframes to-right {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: var(--selector-active-location);
	}
}

.tab_selector.tabtwo {
	transform: var(--selector-active-location);
	animation: to-right 0.6s cubic-bezier(0.23, 1, 0.32, 1) both;
}

.vertical_tab {
	display: flex;
	flex-direction: column;
}

.vertical_tab img {
	width: 2rem;
	height: 2rem;
	padding: 0.17rem;
}
