.user_general_stats {
	grid-area: user_general_stats;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}
.user_general_stats_1, .user_general_stats_2 {
	display: flex;
	gap: 0.5rem;
}
.user_general_stats_1 > div, .user_general_stats_2 > div {
	border-radius: 30px;
	background: var(--cards-effect-background);
	backdrop-filter: var(--cards-effect-blur);
	box-shadow: var(--cards-effect-shadow);
	border: var(--cards-effect-border);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 130px;
	max-width: 270px;
	width: 230px;
	gap: 0.3rem;
}

.user_general_stats_1 > div > p, .user_general_stats_2 > div > p {
	font-size: 2.5rem;
	font-weight: bold;
	color: var(--text-primary);
}

@media screen and (max-width: 1360px){
    .user_general_stats_1 > div, .user_general_stats_2 > div {
		border-radius: 30px;
		width: 100%;
		max-width: 100%;

	}
}