.waitingContainer {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 2rem;
	position: absolute;
	top: 20px;
	left: 0;
	overflow: scroll;
}

.timer {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.value {
	color: #fff;
	font-size: 3rem;
}

.startGame {
	color: #fff;
	font-size: 3rem;
	text-align: center;
	font-weight: bold;
	font-family: 'Snes_Regular';
	animation: bounceInAndFadeIn 1s;
	transition: all 1s;
	width: 100%;
}
.startGameTitle {
	margin-bottom: 0px;
}

@media (max-width: 840px) {
	.startGameTitle {
		font-size: 2rem !important;
	}
}

@keyframes bounceInAndFadeIn {
	0% {
		opacity: 0;
		transform: scale(0.1);
	}
	60% {
		opacity: 0.5;
		transform: scale(1.2);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}

.startGameTitle {
	color: #fff;
	font-size: 3rem;
	text-align: center;
	font-weight: normal;
	font-family: 'Snes_Regular';
	animation: bounceInAndFadeIn 1s;
	transition: all 1s;
}