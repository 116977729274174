.no_data_view {
	width: 100%;
	height: 100%;
	min-height: 400px;
	flex-direction: column;
	display: flex;
	justify-content: center;
	align-items: center;
}

.no_data_view img {
	width: 100px;
	height: 100px;
}

.no_data_view h1 {
	font-size: 18px;
	color: var(--text-primary);
	margin-top: 20px;
}

.no_data_view p {
	font-size: 15px;
	color: var(--text-secondary);
	margin-top: 10px;
	max-width: 500px;
	text-align: center;
	padding: 0 10px;
}
